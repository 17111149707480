import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[awesome-grid-left]',
})
export class AwesomeGridLeftDirective {
  constructor(private el: ElementRef) {}
}

@Directive({
  selector: '[awesome-grid-center]',
})
export class AwesomeGridCenterDirective {
  constructor(private el: ElementRef) {}
}

@Directive({
  selector: '[awesome-grid-right]',
})
export class AwesomeGridRightDirective {
  constructor(private el: ElementRef) {}
}
