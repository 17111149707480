<div
  [ngStyle]="{ 'width.px': widths.status }"
  class="timesheet__item timesheet__status"
  [ngClass]="{
    'timesheet__status--approved': timesheet.status === 'Approved',
    'timesheet__status--declined': timesheet.status === 'Declined',
    'timesheet__status--pending': timesheet.status === 'Pending'
  }"
></div>

<div [ngStyle]="{ 'width.px': widths.start }" class="timesheet__item justify-between">
  {{ timesheet.starttime | time }}
  <shift-warning
    [absenceWarning]="absenceWarning"
    [doubleRegistration]="doubleRegistration"
    [deviatesFromSchedule]="deviationsFromSchedule.starttime"
  ></shift-warning>
</div>

<div *ngIf="!timesheet.endtime" [ngStyle]="{ 'width.px': widths.end }" class="timesheet__item">--:--</div>
<div *ngIf="!!timesheet.endtime" [ngStyle]="{ 'width.px': widths.end }" class="timesheet__item justify-between">
  {{ timesheet.endtime | time }}
  <shift-warning
    [absenceWarning]="absenceWarning"
    [doubleRegistration]="doubleRegistration"
    [deviatesFromSchedule]="deviationsFromSchedule.endtime"
  ></shift-warning>
</div>

<div *ngIf="cols.break" [ngStyle]="{ 'width.px': widths.break }" class="timesheet__item justify-between">
  {{ timesheet.break }}
  <shift-warning
    [absenceWarning]="absenceWarning"
    [doubleRegistration]="doubleRegistration"
    [deviatesFromSchedule]="deviationsFromSchedule.break"
  ></shift-warning>
</div>

<location-info *ngIf="cols.location" [width]="widths.location" [department]="department" [locations]="locations">
</location-info>

<div *ngIf="cols.department" [ngStyle]="{ 'width.px': widths.department }" class="timesheet__item">
  <timesheet-info
    [width]="widths.department"
    [text]="department?.name"
    [absenceWarning]="absenceWarning"
    [doubleRegistration]="doubleRegistration"
    [deviatesFromSchedule]="deviationsFromSchedule.department"
  ></timesheet-info>
</div>

<div *ngIf="cols.team" [ngStyle]="{ 'width.px': widths.team }" class="timesheet__item">
  <timesheet-info
    [width]="widths.team"
    [text]="timesheet.Team?.name"
    [absenceWarning]="absenceWarning"
    [doubleRegistration]="doubleRegistration"
    [deviatesFromSchedule]="deviationsFromSchedule.team"
  >
  </timesheet-info>
</div>

<div *ngIf="cols.shift" [ngStyle]="{ 'width.px': widths.shift }" class="timesheet__item">
  <timesheet-info
    [width]="widths.shift"
    [text]="timesheet.Shift?.long_name"
    [absenceWarning]="absenceWarning"
    [doubleRegistration]="doubleRegistration"
    [deviatesFromSchedule]="deviationsFromSchedule.shift"
  >
  </timesheet-info>
</div>

<div *ngIf="cols.surcharge" [ngStyle]="{ 'width.px': widths.surcharge }" class="timesheet__item">
  <timesheet-info
    [width]="widths.surcharge"
    [text]="surcharge"
    [absenceWarning]="absenceWarning"
    [doubleRegistration]="doubleRegistration"
  ></timesheet-info>
</div>

<div *ngIf="cols.km" [ngStyle]="{ 'width.px': widths.km }" class="timesheet__item">
  <timesheet-info
    [width]="widths.km"
    text="{{ timesheet.kilometers | decimal }}"
    [absenceWarning]="absenceWarning"
    [doubleRegistration]="doubleRegistration"
  ></timesheet-info>
</div>

<div *ngIf="cols.meals" [ngStyle]="{ 'width.px': widths.meals }" class="timesheet__item">
  <timesheet-info
    [width]="widths.meals"
    [text]="timesheet.meals"
    [absenceWarning]="absenceWarning"
    [doubleRegistration]="doubleRegistration"
  ></timesheet-info>
</div>

<div *ngIf="cols.note" [ngStyle]="{ 'width.px': widths.note }" class="timesheet__item">
  <sb-badge
    *ngIf="timesheet.note"
    [size]="'large'"
    [color]="'blue'"
    [sbTooltip]="timesheet.note"
    [sbTooltipPlacement]="'top'"
  >
    <sb-icon [name]="'comment'"></sb-icon>
  </sb-badge>
</div>

<timesheet-row-custom-fields
  [timesheet]="timesheet"
  [canEdit]="false"
  [columns]="customFieldCols"
  [customFields]="customFields"
></timesheet-row-custom-fields>

<div [ngStyle]="{ 'width.px': widths.details }" class="timesheet__item">
  <timesheet-details
    [timesheet]="timesheet"
    [absenceWarning]="absenceWarning"
    [doubleRegistration]="doubleRegistration"
    [cols]="cols"
  ></timesheet-details>
</div>
