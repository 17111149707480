import { Directive, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { getAccountSubscription } from '@app/reducers/account/account.service';
import { hasReachedQuota } from '@app/shared/subscription-plan/subscription-quota.directive';
import { Store } from '@ngrx/store';
import { SB_SPLIT_BUTTON, SplitButtonComponent } from '@sb/ui';
import { Subscription } from 'rxjs';

import { AppState } from '../../reducers/index';
import { QuotaType } from '../+reports/shared/subscriptions/subscription.model';

@Directive({
  selector: '[splitButtonQuota]',
  standalone: true,
})
export class SplitButtonQuotaDirective implements OnInit, OnDestroy {
  private accountSubscription: Subscription;

  @Input('splitButtonQuota')
  public quota: QuotaType;

  public constructor(
    private store: Store<AppState>,
    @Inject(SB_SPLIT_BUTTON)
    private splitButton: SplitButtonComponent,
  ) {}

  public ngOnInit() {
    this.accountSubscription = this.store.select(getAccountSubscription).subscribe((subscription) => {
      if (subscription && this.quota) {
        if (hasReachedQuota(this.quota, subscription)) {
          this.splitButton.disabled = true;
          this.splitButton.icon = 'lock';
        }
      }
    });
  }

  public ngOnDestroy(): void {
    if (this.accountSubscription) {
      this.accountSubscription.unsubscribe();
    }
  }
}
