import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { EnhancedAbsenceModel } from '../../../../../../reducers/orm/absence/absence.model';
import { AbsenceOptionUnit } from '@app/enums';

@Component({
  selector: 'timesheet-row-absence',
  templateUrl: './timesheet-row-absence.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimesheetRowAbsenceComponent {
  public absenceOptionUnit = AbsenceOptionUnit;

  @HostBinding('class')
  private readonly classes = 'timesheet__absence-info';
  @Input()
  public date: string;
  @Input()
  public absence: EnhancedAbsenceModel;
  @Input()
  public widths;
}
