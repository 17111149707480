<div
  *ngIf="!schedule.hasTimesheet"
  [ngStyle]="{ 'width.px': widths.status }"
  class="timesheet__item timesheet__status timesheet__status--declined"
></div>
<div
  *ngIf="schedule.hasTimesheet"
  [ngStyle]="{ 'width.px': widths.status }"
  class="timesheet__item timesheet__status"
  [ngClass]="{
    'timesheet__status--approved': schedule.timesheet.status === 'Approved',
    'timesheet__status--declined': schedule.timesheet.status === 'Declined',
    'timesheet__status--pending': schedule.timesheet.status === 'Pending'
  }"
></div>

<div [ngStyle]="{ 'width.px': widths.start }" class="timesheet__item">
  {{ schedule.starttime | time }}
</div>

<div *ngIf="!schedule.hide_end_time" [ngStyle]="{ 'width.px': widths.end }" class="timesheet__item">
  {{ schedule.endtime | time }}
</div>
<div *ngIf="schedule.hide_end_time" [ngStyle]="{ 'width.px': widths.end }" class="timesheet__item">---</div>

<div *ngIf="cols.break" [ngStyle]="{ 'width.px': widths.break }" class="timesheet__item">
  {{ schedule.break }}
</div>

<location-info *ngIf="cols.location" [width]="widths.location" [department]="department" [locations]="locations">
</location-info>

<div *ngIf="cols.department" [ngStyle]="{ 'width.px': widths.department }" class="timesheet__item">
  <timesheet-info [width]="widths.department" [text]="department?.name"></timesheet-info>
</div>
<div *ngIf="cols.team" [ngStyle]="{ 'width.px': widths.team }" class="timesheet__item">
  <timesheet-info [width]="widths.team" [text]="schedule.Team?.name"></timesheet-info>
</div>
<div *ngIf="cols.shift" [ngStyle]="{ 'width.px': widths.shift }" class="timesheet__item">
  <timesheet-info [width]="widths.shift" [text]="schedule.Shift?.long_name"></timesheet-info>
</div>

<div *ngIf="widths.scheduleCenter > 0" [ngStyle]="{ 'width.px': widths.scheduleCenter }" class="timesheet__item"></div>

<div [ngStyle]="{ 'width.px': widths.details }" class="timesheet__item timesheet__details">
  <span class="timesheet__schedule-label" translate>Scheduled</span>
</div>
