import { Component, DestroyRef, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { AppState } from '@reducers/index';
import { EnhancedAbsenceModel } from '@reducers/orm/absence/absence.model';
import {
  AbsenceService,
  filterPendingAbsences,
  filterReviewedAbsences,
  getUserAbsence,
} from '@reducers/orm/absence/absence.service';
import { addYears, endOfYear, startOfYear } from 'date-fns';
import { BehaviorSubject, Observable, combineLatest as observableCombineLatest } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';

import { format, parseDate, yearList } from '../../../../shared/date.helper';

// TODO this interface shouldn't exist, but its still being (ab)used in various places. Will leave it for now.
export interface EmployeeOverviewData {
  id?: string;
  startYear?: string;
  endDate?: string;
  departments?: string[];
}

@Component({
  selector: 'employee-absence-overview',
  templateUrl: './employee-absence-overview.component.html',
})
export class EmployeeAbsenceOverviewComponent implements OnInit {
  private employeeChange$: BehaviorSubject<EmployeeOverviewData> = new BehaviorSubject({});
  private _employee: EmployeeOverviewData;

  @Input()
  public set employee(employee: EmployeeOverviewData) {
    this._employee = employee;
    this.employeeChange$.next(employee);

    if (employee) {
      this.updateYearOptions(employee.startYear);
    }
  }

  public get employee(): EmployeeOverviewData {
    return this._employee;
  }

  public absencePending: EnhancedAbsenceModel[] = [];
  public absenceReviewed: EnhancedAbsenceModel[] = [];
  public loadingAbsence = true;

  public years: string[] = [];
  public selectedYear: string;
  private selectedYear$ = new BehaviorSubject(format(new Date(), 'yyyy'));

  public constructor(
    private readonly store: Store<AppState>,
    private readonly absenceService: AbsenceService,
    private readonly destroyRef: DestroyRef,
  ) {}

  public ngOnInit() {
    void this.selectedYear$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((selectedYear) => {
      this.selectedYear = selectedYear;
    });

    this.setAbsenceSubscription();
  }

  public selectYear(year: string) {
    this.selectedYear$.next(year);
    this.resetStats();
  }

  private setAbsenceSubscription() {
    void this.getUserAndYear()
      .pipe(
        tap(() => (this.loadingAbsence = true)),
        switchMap((userAndYear) => {
          const year = userAndYear.year;
          const startDate = format(startOfYear(parseDate(year)), 'yyyy-MM-dd');
          const endDate = format(endOfYear(parseDate(year)), 'yyyy-MM-dd');

          return this.getAbsence(userAndYear.user.id, startDate, endDate);
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((absences) => {
        this.loadingAbsence = false;
        this.absencePending = absences.absencePending;
        this.absenceReviewed = absences.absenceReviewed;
      });
  }

  private getUserAndYear(): Observable<{ user: EmployeeOverviewData; year: string }> {
    return observableCombineLatest([this.employeeChange$, this.selectedYear$], (user, year) => {
      user = user && user.id ? user : void 0;
      return { user, year };
    }).pipe(filter((result) => !!result.user));
  }

  private getAbsence(userId: string, minDate: string, maxDate: string) {
    const absenceRequest = {
      userId,
      minDate: minDate,
      maxDate: maxDate,
    };

    return this.absenceService.load(absenceRequest).pipe(
      switchMap(() => this.store.select(getUserAbsence(userId, minDate, maxDate))),
      map((absences) => {
        const absencePending = filterPendingAbsences(absences);
        const absenceReviewed = filterReviewedAbsences(absences);

        return { absencePending, absenceReviewed };
      }),
    );
  }

  private resetStats() {
    this.absencePending = [];
    this.absenceReviewed = [];
  }

  private updateYearOptions(year: string) {
    if (!year) {
      return;
    }

    const until = format(addYears(new Date(), 4), 'yyyy');
    if (until < year) {
      year = until;
    }

    this.years = yearList(year, until);
  }
}
