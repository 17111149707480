<div *ngIf="visible" [class.timesheet-grid]="last" class="timesheet-day-block" [ngStyle]="{ 'height.px': height }">
  <div *ngIf="show">
    <span class="timesheet-day-block__title">{{ date | date: 'eeeeee P' }}</span>

    <div class="timesheet-totals">
      <div class="timesheet-totals__time">
        <small>{{ total.hours | decimalToTime }}</small>
      </div>
      <div
        *ngIf="showSalary"
        [sbTooltipPlacement]="'top'"
        class="timesheet-totals__salary"
        [sbTooltip]="'Worked - Scheduled' | translate"
      >
        <ng-container *ngIf="scheduleDiff != 0">
          <small
            ><span>{{ scheduleDiff | decimalToTime }}</span></small
          >
        </ng-container>
      </div>
    </div>
  </div>
</div>
