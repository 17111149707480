import { Component, DestroyRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbsenceRequestDirective } from '@app/+modals/employee/absence-request/absence-request.directive';
import { getEnhancedAbsenceById } from '@app/reducers/orm/absence/absence.service';
import { AbsenceTooltipComponent } from '@app/shared/absence/absence-tooltip/absence-tooltip.component';
import { Store } from '@ngrx/store';
import { AppState } from '@reducers/index';
import { AbsenceModel, EnhancedAbsenceModel } from '@reducers/orm/absence/absence.model';
import { scheduleCompactViewEnabled } from '@reducers/page-filters/page-filters.selector';
import { TooltipDirective, TooltipModule } from '@sb/tooltip';
import { take } from 'rxjs/operators';

import { AbsenceItemContentComponent } from './absence-item-content/absence-item-content.component';

@Component({
  selector: 'absence-item',
  templateUrl: './absence-item.component.html',
  standalone: true,
  imports: [TooltipModule, AbsenceRequestDirective, AbsenceTooltipComponent, AbsenceItemContentComponent],
})
export class AbsenceItemComponent implements OnInit, OnDestroy {
  @Input({ required: true })
  public set absence(absence: AbsenceModel | EnhancedAbsenceModel) {
    if (absence.absenteeOption) {
      this.enhancedAbsence = absence as EnhancedAbsenceModel;
    } else {
      this.setEnhancedAbsence(absence.id);
    }
  }

  @Input({ required: true })
  public date: string;

  @ViewChild(TooltipDirective, { static: true })
  public tooltip: TooltipDirective;

  @Input()
  public mode: 'team' | 'employee' = 'employee';

  @Input()
  public compactView: boolean;

  // This prop is used in the template to make sure absence-item-content
  // doesn't have to "enhance" the absence again
  public enhancedAbsence: EnhancedAbsenceModel;

  public constructor(
    private readonly store: Store<AppState>,
    private readonly destroyRef: DestroyRef,
  ) {}

  public ngOnInit(): void {
    if (this.compactView === undefined) {
      void this.store
        .select(scheduleCompactViewEnabled)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((compactViewEnabled: boolean) => {
          this.compactView = compactViewEnabled;
        });
    }
  }

  public ngOnDestroy(): void {
    if (this.tooltip) {
      this.tooltip.hide();
    }
  }

  private setEnhancedAbsence(id: string) {
    void this.store
      .select(getEnhancedAbsenceById(id))
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe((absence: EnhancedAbsenceModel) => {
        this.enhancedAbsence = absence;
      });
  }
}
