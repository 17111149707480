import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, HostBinding, Input } from '@angular/core';
import { Cell } from '../../../../../shared/awesome-grid/interfaces';

@Component({
  selector: 'timesheet-content-row',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'timesheet-content-row',
  },
  template: ` <ng-content></ng-content> `,
  providers: [{ provide: Cell, useExisting: forwardRef(() => TimesheetContentRowComponent) }],
})
export class TimesheetContentRowComponent extends Cell {
  @HostBinding('style.backgroundSize')
  public backgroundSize: string;

  @HostBinding('class.timesheet-grid')
  @Input()
  public grid = true;

  constructor(cd: ChangeDetectorRef) {
    super(cd);
  }
}
