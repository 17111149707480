<span class="timesheet-info" [ngStyle]="{ 'width.px': width }" [sbTooltip]="text" [sbTooltipDisabled]="!isTruncated">
  <ng-container *ngIf="text; else externText">
    <span class="timesheet-info-text">{{ text }}</span>
    <shift-warning
      [absenceWarning]="absenceWarning"
      [doubleRegistration]="doubleRegistration"
      [deviatesFromSchedule]="deviatesFromSchedule"
    ></shift-warning>
  </ng-container>
  <ng-template #externText>
    {{ 'External' | translate }}
  </ng-template>
</span>
