import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LocationModel } from '../../../../../../../reducers/orm/location/location.model';
import { DepartmentModel } from '../../../../../../../reducers/orm/department/department.model';

@Component({
  selector: 'location-info',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './location-info.component.html',
})
export class LocationInfoComponent {
  @Input()
  locations: { [id: string]: LocationModel };
  @Input()
  department: DepartmentModel;
  @Input()
  width: number;

  public location = '';

  ngOnChanges() {
    this.location = this.getLocation();
  }

  getLocation() {
    if (this.locations && this.department) {
      const loc = this.locations[this.department.location_id];

      return loc ? loc.name : '';
    } else {
      return '';
    }
  }
  Ø;
}
