<div *ngIf="visible" class="timesheet-grid timesheet-content-row" [ngStyle]="{ 'height.px': height }">
  <div class="timesheet__actions">
    <ng-content></ng-content>
    <a
      *absenteePermission="absence; type: 'edit'"
      class="btn btn-secondary button-icon button-edit inline-flex items-center justify-center"
      [attr.aria-label]="'Edit' | translate"
      [appAbsenceRequest]="{ absenceId: absence.id, action: 'edit', originator: 'timesheet' }"
    >
      <icon class="si" svgIcon="edit"></icon>
    </a>
    <br />
  </div>

  <ng-container *ngIf="showTotals">
    <div class="timesheet__totals bg-transparent">
      <div class="timesheet-totals">
        <ng-template #absenceTooltip>
          <ng-content select="[absence-tooltip]"></ng-content>
        </ng-template>

        <div class="timesheet-totals__time" [sbTooltip]="absenceTooltip" [sbTooltipPlacement]="'top'">
          {{ absence.periodHours | decimalToTime }}
        </div>
        <ng-container *subscriptionPlan="planType.BASIC">
          <div *ngIf="showSalary" class="timesheet-totals__salary">{{ absence.periodPay | money }}</div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>
