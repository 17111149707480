import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'sidebar-header',
  standalone: true,
  imports: [CommonModule],
  template: `
    <div class="flex h-10 items-center justify-between bg-grey-100 px-3 font-medium text-word-mark-800">
      <span>
        {{ title }}
      </span>
      @if (indicator && indicator > 0) {
        <span class="flex h-5 min-w-5 items-center justify-center rounded-full bg-error-500 p-1 font-bold text-white">
          {{ indicator }}
        </span>
      }
    </div>
  `,
})
export class SidebarHeaderComponent {
  @Input({ required: true }) public title: string;

  @Input() public indicator: number;
}
