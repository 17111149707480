import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CalendarComponent, CalendarDay, IconComponent, LegendComponent, LegendItem } from '@sb/ui';
import { endOfDay, isWithinInterval, startOfDay, startOfMonth } from 'date-fns';
import { take } from 'rxjs';

import { AppState } from '../../../../../reducers';
import { AbsenceStatus, EnhancedAbsenceModel } from '../../../../../reducers/orm/absence/absence.model';
import { AbsenteeOptionModel } from '../../../../../reducers/orm/absentee-option/absentee-option.model';
import { getAllAbsenteeOptions } from '../../../../../reducers/orm/absentee-option/absentee-option.service';
import { AbsenceCalendarDayComponent } from './absence-calendar-day.component';

@Component({
  selector: 'absence-calendar',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    LegendComponent,
    TranslateModule,
    RouterLink,
    IconComponent,
    CalendarComponent,
    AbsenceCalendarDayComponent,
  ],
  templateUrl: './absence-calendar.component.html',
})
export class AbsenceCalendarComponent implements OnChanges {
  @Input()
  public year: string;
  @Input()
  public pendingAbsences: EnhancedAbsenceModel[] = [];
  @Input()
  public reviewedAbsences: EnhancedAbsenceModel[] = [];

  public date: Date;
  public minDate: Date;
  public maxDate: Date;
  public locale: string;
  public legendItems: LegendItem[] = [];

  private absenceTypes: AbsenteeOptionModel[];
  private filteredReviewedAbsences: EnhancedAbsenceModel[] = [];
  public defaultPanelStyles = {};

  public constructor(
    private store: Store<AppState>,
    private translate: TranslateService,
  ) {
    void this.store
      .select(getAllAbsenteeOptions)
      .pipe(take(1))
      .subscribe({
        next: (absenteeOptions) => {
          this.absenceTypes = absenteeOptions;
        },
      });

    this.locale = this.translate.currentLang;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['reviewedAbsences']) {
      this.filteredReviewedAbsences = this.reviewedAbsences.filter(
        (absence) => absence.status !== AbsenceStatus.DECLINED,
      );
    }

    if (changes['pendingAbsences'] || changes['reviewedAbsences']) {
      this.legendItems = this.getLegendItems();
    }

    if (changes['year']) {
      const currentDate = startOfMonth(this.date ? new Date(this.date) : new Date());
      currentDate.setFullYear(Number(this.year));
      this.date = currentDate;
      this.minDate = new Date(`${this.year}-01-01`);
      this.maxDate = new Date(`${this.year}-12-31`);
    }
  }

  public onDateChange(date: Date): void {
    this.date = new Date(date);
  }

  public getDayAbsences(day: CalendarDay): EnhancedAbsenceModel[] {
    return this.filteredReviewedAbsences
      .concat(this.pendingAbsences)
      .filter((absence) => {
        const absenceStartDate = startOfDay(new Date(absence.startdate));
        const absenceEndDate = endOfDay(new Date(absence.enddate));
        return isWithinInterval(day.date, { start: absenceStartDate, end: absenceEndDate });
      })
      .sort((a, b) => Number(a.absenteeOption.weight) - Number(b.absenteeOption.weight));
  }

  // Determine unique absence types out of pending and reviewed absences
  private getLegendItems(): LegendItem[] {
    const pendingAbsenceTypes = this.pendingAbsences.map((absence) => absence.absentee_option_id);
    const reviewedAbsenceTypes = this.filteredReviewedAbsences.map((absence) => absence.absentee_option_id);
    return [...new Set([...pendingAbsenceTypes, ...reviewedAbsenceTypes])].map((id) => {
      const type = this.getAbsenceType(id);
      return { label: type.option, color: type.color };
    });
  }

  private getAbsenceType(id: string): AbsenteeOptionModel {
    return this.absenceTypes.find((absence) => absence.id === id);
  }
}
