import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, HostBinding, Input } from '@angular/core';

import { EmployeeModel } from '../../../../../../reducers/orm/employee/employee.model';
import { defaultTotal } from '../../../../../../shared/total.helper';
import { Cell } from '../../../../../shared/awesome-grid/interfaces';

@Component({
  selector: 'timesheet-employee',
  templateUrl: './timesheet-employee.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: Cell, useExisting: forwardRef(() => TimesheetEmployeeComponent) }],
})
export class TimesheetEmployeeComponent extends Cell {
  @Input()
  public employee: EmployeeModel;
  @Input()
  public total = defaultTotal;
  @Input()
  public showSalary: boolean;
  @Input()
  public canViewEmployee = true;
  @Input()
  public scheduleDiff;
  @Input()
  public show = true;
  @Input()
  public last = true;
  @Input()
  public teamId: string;

  @HostBinding('style.height.px')
  public height = 36;

  public constructor(cd: ChangeDetectorRef) {
    super(cd);
  }
}
