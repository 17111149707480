<div *ngIf="visible" class="timesheet-grid" [ngStyle]="{ 'height.px': height }">
  <div class="timesheet__actions">
    <ng-container *ngIf="!dayIsClosed">
      <a
        *permission="['Create timesheets', 'Create own timesheet']; user: employeeId; department: 'any'"
        [routerLink]="['', { outlets: { modal: ['timesheet', { schedule: schedule.occurrence_id }] } }]"
        class="btn btn-secondary button-icon button-add"
        [attr.aria-label]="'Add' | translate"
      >
        <icon class="si" svgIcon="sum"></icon>
      </a>
    </ng-container>
  </div>
</div>
