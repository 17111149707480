import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import {
  BadgeIconComponent,
  IconButtonComponent,
  IconComponent,
  StatusButtonComponent,
  StatusIconComponent,
} from '@sb/ui';

import { TooltipModule } from '../../../../../../../libs/tooltip/src';
import { AbsenceRequestDirective } from '../../../../../+modals/employee/absence-request/absence-request.directive';
import { AbsenceOptionUnit } from '../../../../../enums';
import { DecimalToDurationFormatPipe } from '../../../../../pipes/decimal-to-duration-format.pipe';
import { LocaleDatePipe } from '../../../../../pipes/locale-date.pipe';
import { EnhancedAbsenceModel } from '../../../../../reducers/orm/absence/absence.model';
import { AbsenceService } from '../../../../../reducers/orm/absence/absence.service';
import { AbsenceBadgeIconDirective } from '../../../../../shared/absence/absence-badge-icon.directive';

@Component({
  selector: 'absence-row',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    TooltipModule,
    TranslateModule,
    AbsenceBadgeIconDirective,
    AbsenceRequestDirective,
    LocaleDatePipe,
    DecimalToDurationFormatPipe,
    BadgeIconComponent,
    IconComponent,
    IconButtonComponent,
    StatusButtonComponent,
    StatusIconComponent,
  ],
  templateUrl: './absence-row.component.html',
})
export class AbsenceRowComponent {
  @Input()
  public absence: EnhancedAbsenceModel;
  @Input()
  public isOwnAbsence: boolean;

  public AbsenceOptionUnit = AbsenceOptionUnit;

  public constructor(private absenceService: AbsenceService) {}

  public changeStatus(absentee, status) {
    const updateData = {
      id: absentee.id,
      // TODO temporary decision to always notify the employee on status change
      notify_employee: !this.isOwnAbsence && this.absence.canApprove,
      status,
    };

    void this.absenceService.save(updateData).subscribe();
  }
}
