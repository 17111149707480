<div>
  <form [formGroup]="form">
    <div class="flex h-14 items-center justify-between bg-primary-500 pl-3 font-semibold text-white">
      <div class="sidebar__nav-header--title pr-2.5">{{ 'Timesheet settings' | translate }}</div>
      <div class="sidebar__nav-header--close">
        <button sb-button [color]="'primary'" (click)="close()" class="sb-button-responsive-label">
          {{ 'Close' | translate }}
          <sb-icon iconPositionEnd [name]="'chevron-right'"></sb-icon>
        </button>
      </div>
    </div>

    <div class="sidebar__content" formGroupName="show">
      <ng-container *ngFor="let field of showOptions">
        <div *ngIf="!periodPage || field.showOnPeriod" class="sidebar__element-container">
          <label class="checkbox">
            <input type="checkbox" class="checkbox-input" [formControlName]="field.field" />
            <span class="checkbox-indicator"></span>
            <span class="checkbox-description text-word-mark-800">{{ field.label }}</span>
          </label>
        </div>
      </ng-container>
    </div>

    <sidebar-header [title]="'Status' | translate" />
    <div class="sidebar__content" formGroupName="status">
      <div *ngFor="let field of statusOptions" class="sidebar__element-container">
        <label class="checkbox">
          <input type="checkbox" class="checkbox-input" [formControlName]="field.field" />
          <span class="checkbox-indicator"></span>
          <span class="checkbox-description text-word-mark-800">{{ field.label }}</span>
        </label>
      </div>
    </div>

    <sidebar-header [title]="'Type' | translate" />
    <div class="sidebar__content" formGroupName="type">
      <div *ngFor="let field of typeOptions" class="sidebar__element-container">
        <label class="checkbox">
          <input type="checkbox" class="checkbox-input" [formControlName]="field.field" />
          <span class="checkbox-indicator"></span>
          <span class="checkbox-description text-word-mark-800">{{ field.label }}</span>
        </label>
      </div>
    </div>

    <sidebar-header [title]="'Conflicts' | translate" />
    <div class="sidebar__content" formGroupName="type">
      <div *ngFor="let field of conflictOptions" class="sidebar__element-container">
        <label class="checkbox">
          <input type="checkbox" class="checkbox-input" [formControlName]="field.field" />
          <span class="checkbox-indicator"></span>
          <span class="checkbox-description text-word-mark-800">{{ field.label }}</span>
        </label>
      </div>
    </div>

    <ng-container *ngIf="!!columns">
      <sidebar-header [title]="'Columns' | translate" />
      <div class="sidebar__content" formGroupName="columns">
        <div *ngFor="let column of columnOptions" class="sidebar__element-container">
          <label class="checkbox">
            <input type="checkbox" class="checkbox-input" [formControlName]="column.field" />
            <span class="checkbox-indicator"></span>
            <span class="checkbox-description text-word-mark-800">{{ column.label | translate }}</span>
          </label>
        </div>
      </div>
    </ng-container>

    @if (customFieldOptions?.length > 0 && form.get('customFields')) {
      <sidebar-header [title]="'Custom fields' | translate" />
      <div class="sidebar__content" formGroupName="columns">
        <div *ngFor="let column of customFieldOptions" class="sidebar__element-container">
          <label class="checkbox">
            <input type="checkbox" class="checkbox-input" [formControl]="form.get('customFields').get(column.field)" />
            <span class="checkbox-indicator"></span>
            <span class="checkbox-description text-word-mark-800">{{ column.label | translate }}</span>
          </label>
        </div>
      </div>
    }
  </form>
</div>
