import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbsenceTooltipComponent } from '@app/shared/absence/absence-tooltip/absence-tooltip.component';
import { TooltipModule } from '@sb/tooltip';
import { CalendarDay, CalendarDayComponent } from '@sb/ui';
import { clsx } from 'clsx';

import { EnhancedAbsenceModel } from '../../../../../reducers/orm/absence/absence.model';

@Component({
  standalone: true,
  selector: 'absence-calendar-day',
  imports: [CommonModule, TooltipModule, CalendarDayComponent, AbsenceTooltipComponent],
  templateUrl: './absence-calendar-day.component.html',
})
export class AbsenceCalendarDayComponent implements OnChanges {
  public classes = '';
  public styles = {};

  @Input()
  public day: CalendarDay;

  @Input()
  public absences: EnhancedAbsenceModel[] = [];

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['absences']) {
      if (this.absences.length === 0) {
        this.classes = clsx({
          'opacity-50': this.day.isOutOfMonth,
        });
        this.styles = {};
        return;
      }

      this.classes = clsx({
        'text-white': this.absences[0].status !== 'Pending' && this.absences[0].absenteeOption.color_is_dark,
        'opacity-50': this.day.isOutOfMonth,
        'border-2 border-solid': true,
      });
      this.styles = {
        borderColor: this.absences[0].absenteeOption.color,
        backgroundColor: this.absences[0].status !== 'Pending' ? this.absences[0].absenteeOption.color : '',
      };
    }
  }
}
