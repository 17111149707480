@if (!canEdit) {
  @for (customField of customFields; track customField.id) {
    @if (columns && columns[customField.id]) {
      <div [ngStyle]="{ 'width.px': customField.width }" class="timesheet__item text-ellipsis">
        @if (timesheet.custom_fields && timesheet.custom_fields[customField.id]) {
          @switch (customField.type) {
            @case (customFieldType.BOOLEAN) {
              {{ timesheet.custom_fields[customField.id] | yesNo }}
            }
            @case (customFieldType.DECIMAL) {
              {{ timesheet.custom_fields[customField.id] | decimal }}
            }
            @case (customFieldType.MONEY) {
              {{ timesheet.custom_fields[customField.id] | money }}
            }
            @case (customFieldType.DATE) {
              {{ timesheet.custom_fields[customField.id] | date: 'dd MMM yy' }}
            }
            @case (customFieldType.MULTI_SELECT) {
              {{ timesheet.custom_fields[customField.id] | join: ', ' }}
            }
            @case (customFieldType.TEXT) {
              <sb-badge
                [size]="'large'"
                [color]="'blue'"
                [sbTooltip]="timesheet.custom_fields[customField.id]"
                [sbTooltipPlacement]="'top'"
                class="mr-1 last:mr-0"
              >
                <icon class="button-icon__icon" svgIcon="comment"></icon>
              </sb-badge>
            }
            @case (customFieldType.URL) {
              <a
                class="text-primary-500 underline"
                target="_blank"
                [href]="(timesheet?.custom_fields)[customField.id]"
                >{{ (timesheet?.custom_fields)[customField.id] | truncate: 40 }}</a
              >
            }
            @default {
              {{ timesheet.custom_fields[customField.id] }}
            }
          }
        }
      </div>
    }
  }
} @else {
  @for (customFieldGroup of mappedCustomFields; track customFieldGroup.id) {
    @if (columns && columns[customFieldGroup.id]) {
      <div [ngStyle]="{ 'width.px': customFieldGroup.width }" class="timesheet__item flex items-center">
        @switch (customFieldGroup.type) {
          @case (customFieldType.TEXT) {
            <app-timesheet-row-edit-textarea
              class="block"
              [value]="timesheet.custom_fields?.[customFieldGroup.id]"
              [isSaving]="timesheet.saving"
              [popoverTitle]="customFieldGroup.name"
              (saved)="onCustomFieldChange($event, customFieldGroup.form)"
            ></app-timesheet-row-edit-textarea>
          }
          @case (customFieldType.DECIMAL) {
            <decimal-input
              [formControl]="customFieldGroup.form.get('value')"
              [defaultValue]="''"
              [fullWidth]="true"
            ></decimal-input>
          }
          @case (customFieldType.NUMERIC) {
            <input
              [formControl]="customFieldGroup.form.get('value')"
              class="input"
              step="1"
              pattern="^-?[0-9]\d*(\.\d+)?$"
              type="number"
            />
          }
          @case (customFieldType.MONEY) {
            <money-input [formControl]="customFieldGroup.form.get('value')" [fullWidth]="true"></money-input>
          }
          @case (customFieldType.BOOLEAN) {
            <label class="form__element--checkbox checkbox">
              <input type="checkbox" class="checkbox-input" [formControl]="customFieldGroup.form.get('value')" />
              <span class="checkbox-indicator"></span>
            </label>
          }
          @case (customFieldType.DATE) {
            <date-input
              [formControl]="customFieldGroup.form.get('value')"
              [fullWidth]="true"
              [appendTo]="'body'"
            ></date-input>
          }
          @case (customFieldType.SELECT) {
            <select [formControl]="customFieldGroup.form.get('value')" class="select-small">
              <option value=""></option>
              @if (customFieldGroup.hasDeletedSelectValue) {
                <option [value]="customFieldGroup.form.get('value').value" disabled>
                  {{ customFieldGroup.form.get('value').value }}
                </option>
              }
              @for (fieldType of customFieldGroup.select_options; track $index) {
                <option [value]="fieldType">{{ fieldType }}</option>
              }
            </select>
          }
          @case (customFieldType.MULTI_SELECT) {
            <sb-select
              class="w-full"
              [formControl]="customFieldGroup.form.get('value')"
              [multiSelect]="true"
              [searchable]="true"
              [searchPlaceholder]="'Search' | translate"
              [items]="customFieldGroup.multiSelectOptions"
            ></sb-select>
          }
        }
      </div>
    }
  }
}
