<icon class="size-4 shrink-0" [svgIcon]="absence.absenteeOption?.icon" />
@if (!compactView) {
  <div class="min-w-0 flex-1">
    <div class="truncate text-sm font-bold leading-none">
      @if (absence.AbsenteeDay[date].partial_day) {
        {{ absence.AbsenteeDay[date].startTime | time }} - {{ absence.AbsenteeDay[date].endTime | time }}
      }
      {{ absence.absenteeOption?.option }}
    </div>
    @if (mode === 'team') {
      <div class="mt-0.5 truncate text-xs leading-tight">
        {{ absence.Employee?.name }}
      </div>
    }
  </div>
}
