<div class="sub-header">
  <div>
    <span class="sub-header__title">{{ title }}</span>
  </div>
  <div class="sub-header__navigation">
    <form class="form-inline">
      <div class="form-group mb-0">
        <label class="sub-header__navigation-select" for="selected_period">{{ 'Selected year:' | translate }}</label>
        <select
          id="selected_period"
          name="year"
          class="select"
          [ngModel]="year"
          (ngModelChange)="yearChange.emit($event)"
        >
          <option *ngFor="let yearOption of years" [value]="yearOption">{{ yearOption }}</option>
        </select>
      </div>
    </form>
  </div>
</div>

<div class="summary myplusminus-summary">
  <div class="summary__block">
    <content-state class="summary__block-amount" [isLoading]="!stats?.totals" size="extra-small">
      {{ stats?.totals?.plus_min_start | decimalToTime }}
    </content-state>
    <span class="summary__block-title">
      {{ 'Start balance' | translate }} {{ year }}
      <icon
        class="si"
        svgIcon="info"
        [sbTooltipPlacement]="'top'"
        [sbTooltip]="'Number of plus minus hours at the start of the selected year.' | translate"
      ></icon>
    </span>
  </div>
  <div *ngIf="features.SHOW_TODAY_COUNT | featureFlag" class="summary__block">
    <content-state class="summary__block-amount" [isLoading]="!stats?.totals" size="extra-small">
      {{ stats?.totals?.plus_min_end | decimalToTime }}
    </content-state>
    <span *ngIf="year < currentYear" class="summary__block-title">
      {{ 'Ending balance' | translate }} {{ year }}
      <icon
        class="si"
        svgIcon="info"
        container="body"
        [sbTooltipPlacement]="'top'"
        [sbTooltip]="'Number of plus minus hours at the end of the selected year.' | translate"
      ></icon>
    </span>
    <span *ngIf="year >= currentYear" class="summary__block-title">
      {{ 'Today' | translate }}
      <icon class="si" svgIcon="info" [sbTooltip]="'Number of plus minus hours on today.' | translate"></icon>
    </span>
  </div>
  <div class="summary__block">
    <content-state class="summary__block-amount" [isLoading]="!stats?.totals" size="extra-small">
      {{ stats?.totals?.plus_min | decimalToTime }}
    </content-state>
    <span class="summary__block-title">
      {{ 'Plus minus period' | translate }}
      <icon class="si" svgIcon="info" [sbTooltip]="'Plus minus hours in selected year.' | translate"></icon>
    </span>
  </div>
  <div class="summary__block">
    <content-state class="summary__block-amount" [isLoading]="!stats?.totals" size="extra-small">
      {{ stats?.totals?.plus_min_total_to_last_week | decimalToTime }}
    </content-state>
    <span class="summary__block-title">
      {{ 'Plus minus last week' | translate }}
      <icon
        class="si"
        svgIcon="info"
        [sbTooltip]="'Plus minus hours at the start of the current week.' | translate"
      ></icon>
    </span>
  </div>
</div>

<div class="summary-footer myplusminus-summary__footer">
  <div class="summary-footer__block">
    <span class="summary-footer__block-title">
      <icon class="summary-footer__block-icon" svgIcon="newdocument"></icon>
      {{ 'Contract' | translate }}:
      <content-state class="summary-footer__block-value" [isLoading]="!stats?.totals" size="extra-small">
        {{ stats?.totals?.contract_hours | decimalToTime }}
      </content-state>
    </span>
  </div>
  <div class="summary-footer__block">
    <span class="summary-footer__block-title">
      <icon class="summary-footer__block-icon" svgIcon="stopwatch"></icon>
      {{ 'Worked' | translate }}:
      <content-state class="summary-footer__block-value" [isLoading]="!stats?.totals" size="extra-small">
        {{ stats?.totals?.worked | decimalToTime }}
      </content-state>
    </span>
  </div>
  <ng-container *ngIf="showAbsence">
    <div *ngFor="let absence of absenteeOptions | slice: 0 : 2" class="summary-footer__block">
      <span class="summary-footer__block-title">
        <icon class="summary-footer__block-icon" [svgIcon]="absence.icon"></icon>{{ absence.option }}:
        <content-state class="summary-footer__block-value" [isLoading]="!stats?.totals" size="extra-small">{{
          stats && stats.totals ? (stats.totals['option_' + absence.id] | decimalToTime) : undefined
        }}</content-state>
      </span>
    </div>
  </ng-container>

  <div *ngIf="!showAbsence" class="summary-footer__block">
    <span class="summary-footer__block-title">
      <icon class="summary-footer__block-icon" svgIcon="leave-5"></icon>
      {{ 'Absence' | translate }}:
      <content-state class="summary-footer__block-value" [isLoading]="!stats?.totals" size="extra-small">
        {{ stats?.totals?.absent | decimalToTime }}
      </content-state>
    </span>
  </div>
  <div class="summary-footer__block">
    <span class="summary-footer__block-title">
      <icon class="summary-footer__block-icon" svgIcon="leave-5"></icon>
      {{ 'Correction' | translate }}:
      <content-state class="summary-footer__block-value" [isLoading]="!stats?.totals" size="extra-small">
        {{ stats?.totals?.correction_plus_min | decimalToTime }}
      </content-state>
    </span>
  </div>
</div>

<div class="card">
  <div class="card-heading">
    <div class="card-heading__title">
      {{ 'Overview' | translate }}
      <knowledge-base-article [article]="'plus-minus-hours'"></knowledge-base-article>
    </div>
  </div>
  <div class="card-table">
    <ng-container *ngIf="stats?.week_stats | toArray; let weekStats">
      <content-state
        [isEmpty]="!weekStats.length"
        [isLoading]="loadingStats"
        icon="inbox"
        size="small"
        [title]="'No corrections' | translate"
      >
        <table class="table-border table-plusmin-overview table-uniform-rows">
          <thead>
            <tr>
              <th colspan="2">{{ 'Week nr.' | translate }}</th>
              <th class="hidden-xs-down">{{ 'Period' | translate }}</th>
              <th class="hidden-xs-down">{{ 'Contract hours' | translate }}</th>
              <th>{{ 'Worked (regular)' | translate }}</th>
              <th>{{ 'Worked (overtime)' | translate }}</th>
              <th>{{ 'Absent' | translate }}</th>
              <th class="hidden-xs-down">{{ 'Time surcharge' | translate }}</th>
              <th class="hidden-xs-down">{{ 'Correction' | translate }}</th>
              <th>{{ 'Period' | translate }}</th>
              <th>{{ 'Total' | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let weekStat of weekStats?.slice().reverse()">
              <tr (click)="showWeeks[weekStat.weeknumber] = !showWeeks[weekStat.weeknumber]">
                <td class="pl-0">
                  <a class="btn btn-clear btn-icon weekIcon" aria-label="Show week detail">
                    <icon *ngIf="!showWeeks[weekStat.weeknumber]" class="si btn-icon__icon" svgIcon="arrow_down"></icon>
                    <icon
                      *ngIf="showWeeks[weekStat.weeknumber] && stats.day_stats"
                      class="si btn-icon__icon"
                      svgIcon="arrow-up"
                    ></icon>
                    <sb-spinner
                      *ngIf="showWeeks[weekStat.weeknumber] && !stats.day_stats"
                      class="si btn-icon__icon"
                    ></sb-spinner>
                  </a>
                </td>
                <td>{{ weekStat.weeknumber }}</td>
                <td class="hidden-xs-down">{{ weekStat.start | date }} - {{ weekStat.end | date }}</td>
                <td class="hidden-xs-down">{{ weekStat.contract_hours | decimalToTime: false : '-' }}</td>
                <td>{{ weekStat.regular_time | decimalToTime: false : '-' }}</td>
                <td>{{ weekStat.overtime | decimalToTime: false : '-' }}</td>
                <td>
                  {{ weekStat.absent | decimalToTime: false : '-' }}
                </td>
                <td class="hidden-xs-down">{{ weekStat.surcharge_time | decimalToTime: false : '-' }}</td>
                <td class="hidden-xs-down">{{ weekStat.correction_plus_min | decimalToTime: false : '-' }}</td>
                <td><balance [value]="weekStat.plus_min"></balance></td>
                <td><balance [value]="weekStat.plus_min_end"></balance></td>
              </tr>

              <ng-container *ngIf="showWeeks[weekStat.weeknumber]">
                <tr
                  class="table-plusmin-overview__details"
                  *ngFor="
                    let dayStat of stats.day_stats ? stats.day_stats[weekStat.year + weekStat.weeknumber]?.slice() : []
                  "
                >
                  <td colspan="2">{{ dayStat.date | date: 'eeee' }}</td>
                  <td class="hidden-xs-down">{{ dayStat.date | date }}</td>
                  <td class="hidden-xs-down">{{ dayStat.contract_hours | decimalToTime: false : '-' }}</td>
                  <td>{{ dayStat.regular_time | decimalToTime: false : '-' }}</td>
                  <td>{{ dayStat.overtime | decimalToTime: false : '-' }}</td>
                  <td *ngIf="dayStat.absent && showAbsence">
                    <ng-container *ngFor="let absenteeOption of absenteeOptions">
                      <div *ngIf="dayStat['option_' + absenteeOption.id]">
                        {{ dayStat['option_' + absenteeOption.id] | decimalToTime: false }} ({{
                          absenteeOption.option
                        }})
                      </div>
                    </ng-container>
                  </td>
                  <td *ngIf="dayStat.absent && !showAbsence">
                    {{ dayStat.absent | decimalToTime: false }}
                  </td>
                  <td *ngIf="!dayStat.absent">-</td>
                  <td class="hidden-xs-down">{{ dayStat.surcharge_time | decimalToTime: false : '-' }}</td>
                  <td class="hidden-xs-down">{{ dayStat.correction_plus_min | decimalToTime: false : '-' }}</td>
                  <td><balance [value]="dayStat.plus_min"></balance></td>
                  <td><balance [value]="dayStat.plus_min_end"></balance></td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
      </content-state>
    </ng-container>
  </div>
</div>

<div class="card">
  <div class="card-heading">
    <div class="card-heading__title">
      {{ 'Plus minus corrections' | translate }}
      <knowledge-base-article [article]="'plus/minus-hour-correction'"></knowledge-base-article>
    </div>
    <a
      sb-button
      color="primary"
      *permission="['Edit plus min hours']"
      [routerLink]="['', { outlets: { modal: ['time-off-correction', 'overtime', userId] } }]"
    >
      <sb-icon name="plus"></sb-icon>
      {{ ' Add correction' | translate }}
    </a>
  </div>
  <div class="card-table">
    <content-state
      [isEmpty]="!stats?.corrections.length"
      [isLoading]="loadingCorrections"
      [isLoading]="loadingCorrections"
      icon="leave-5"
      size="small"
      [title]="'No corrections' | translate"
    >
      <table class="table-border table-uniform-rows">
        <thead>
          <tr>
            <th class="p-2">{{ 'Date' | translate }}</th>
            <th class="p-2">{{ 'Hours' | translate }}</th>
            <th class="p-2">{{ 'Pay' | translate }}</th>
            <th class="p-2">{{ 'Payout date' | translate }}</th>
            <th class="p-2">{{ 'Note' | translate }}</th>
            <th class="p-2">{{ 'Created by' | translate }}</th>
            <th class="p-2">{{ 'Updated' | translate }}</th>
            <th *permission="['Edit plus min hours']"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let correction of stats?.corrections">
            <td class="p-2">{{ correction.date | date }}</td>
            <td class="p-2"><balance [value]="correction.hours"></balance></td>
            <td class="p-2" *ngIf="correction.pay">{{ 'Yes' | translate }}</td>
            <td class="p-2" *ngIf="!correction.pay">{{ 'No' | translate }}</td>
            <td class="p-2">{{ correction.payout_date | date }}</td>
            <td class="p-2 w-[350px] whitespace-pre-line break-all">{{ correction.note }}</td>
            <td class="p-2">{{ correction.createdByName }}</td>
            <td class="p-2">{{ correction.updated | date: 'P p' }}</td>
            <td *permission="['Edit plus min hours']">
              <action-confirm
                [attr.aria-label]="'Delete correction' | translate"
                class="btn btn-clear btn-icon"
                (onConfirm)="removeCorrection.emit(correction.id)"
                [icon]="'delete'"
              >
              </action-confirm>
            </td>
          </tr>
        </tbody>
      </table>
    </content-state>
  </div>
</div>
