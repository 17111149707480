<ng-container *ngIf="absence && absence.AbsenteeDay">
  <ng-container *ngIf="absence.AbsenteeDay[date]; let absenteeDay">
    <div
      [ngStyle]="{ 'width.px': widths.status }"
      class="timesheet__item timesheet__status"
      [ngClass]="{
        'timesheet__status--approved': absence.status === 'Approved',
        'timesheet__status--declined': absence.status === 'Declined',
        'timesheet__status--pending': absence.status === 'Pending'
      }"
    ></div>

    <div [ngStyle]="{ 'width.px': widths.start }" class="timesheet__item">
      <span *ngIf="absenteeDay.partial_day">{{ absenteeDay.startTime | time }}</span>
    </div>
    <div [ngStyle]="{ 'width.px': widths.end }" class="timesheet__item">
      <span *ngIf="absenteeDay.partial_day">{{ absenteeDay.endTime | time }}</span>
    </div>

    <div
      *ngIf="widths.absenceCenter > 0"
      [ngStyle]="{ 'width.px': widths.absenceCenter }"
      class="timesheet__item"
    ></div>

    <div [ngStyle]="{ 'width.px': widths.details }" class="timesheet__item timesheet__details">
      <span class="timesheet__schedule-label">{{ 'Absent' | translate }}</span>
      <span class="timesheet__schedule-label">{{ absence.absenteeOption?.option }}</span>
      <span *ngIf="!absenteeDay.partial_day; else partialDayBlock" class="timesheet__schedule-label">
        {{ 'Full day' | translate }}
      </span>
      <ng-template #partialDayBlock>
        <span
          *ngIf="absence.absenteeOption?.unit === absenceOptionUnit.DAYS; else hourPartialDay"
          class="timesheet__schedule-label"
        >
          {{ 'Half day' | translate }}
        </span>
        <ng-template #hourPartialDay>
          <span class="timesheet__schedule-label">
            {{ absenteeDay.start_time | time }} - {{ absenteeDay.endTime | time }}
          </span>
        </ng-template>
      </ng-template>
    </div>
  </ng-container>
</ng-container>
