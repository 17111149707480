import { createSelector } from 'reselect';
import {
  enhanceTimesheetsWithLogAndPermissions,
  getDaylogsForTimesheet,
  getSchedulesForTimesheet,
  getTimesheetMaxDate,
  getTimesheetMinDate,
  getTimesheetRowFilter,
  getTimesheetsForTimesheetPageWithoutDepartmentFilter,
  mapGroupedTimesheetData,
} from '../../../+timesheet/shared/timesheet-helper.service';
import {
  getTimesheets,
  hasTimesheetPermission,
  sumTimesheets,
} from '../../../../reducers/orm/timesheet/timesheet.service';
import { dayList, maxToday, periodFilter } from '../../../../shared/date.helper';
import {
  getEmployeeTeamDepartments,
  getPermissionState,
  hasPermission,
} from '../../../../reducers/auth/permission.helper';
import { getTimesheetFilters } from '../../../../reducers/page-filters/page-filters.service';
import { modelHasEmployee } from '../../../../reducers/orm/employee/employee.service';
import groupBy from 'lodash-es/groupBy';
import {
  absenceForPeriodSplitIntoDays,
  getAbsenceEnhanced,
  sumPeriodAbsence,
} from '../../../../reducers/orm/absence/absence.service';
import { defaultTotal, totalAccumulator } from '../../../../shared/total.helper';
import { sumSchedules } from '../../../../reducers/orm/schedule/schedule.service';
import { TimesheetRowAbsentee, TimesheetRowTimesheet } from '../../../+timesheet/shared/interfaces';
import { EmployeeTimesheetData, EmployeeTimesheetDays } from './interfaces';
import { combinedFilter } from '../../../../reducers/shared/entity.helper';
import { AbsenceModel, AbsenceStatus } from '../../../../reducers/orm/absence/absence.model';

export const getTimesheetEmployeeId = createSelector(getTimesheetFilters, (filters) => filters.employeeId);

export const getTimesheetsForEmployeeTimesheetPage = createSelector(
  getTimesheetEmployeeId,
  getTimesheetMinDate,
  getTimesheetMaxDate,
  getPermissionState,
  getTimesheets,
  (employeeId, minDate, maxDate, permissionState, timesheets) => {
    const filteredTimesheets = timesheets
      .filter(periodFilter(minDate, maxToday(maxDate)))
      .filter(modelHasEmployee(employeeId));

    //User must be able to view all her own timesheets regardless of department
    const viewOwn = hasPermission(
      {
        permissions: 'View own timesheet',
        userId: employeeId,
        departments: 'any',
      },
      permissionState
    );

    if (viewOwn) {
      return filteredTimesheets;
    }

    return filteredTimesheets.filter(
      hasTimesheetPermission(['View all timesheets', 'View own timesheet'], permissionState)
    );
  }
);

const getSchedulesForEmployeeTimesheetPage = createSelector(
  getSchedulesForTimesheet,
  getTimesheetEmployeeId,
  (schedules, employeeId) => schedules.filter(modelHasEmployee(employeeId))
);

const getAbsenceForEmployeeTimesheetPage = createSelector(
  getTimesheetMinDate,
  getTimesheetMaxDate,
  getPermissionState,
  getEmployeeTeamDepartments,
  getAbsenceEnhanced,
  getTimesheetEmployeeId,
  (minDate, maxDate, permissionState, employeeTeamDepartments, absences, employeeId) => {
    const combinedFilters = [
      modelHasEmployee(employeeId),
      (absence: AbsenceModel) => absence.status !== AbsenceStatus.DECLINED,
    ];
    const filteredAbsences = combinedFilter(absences, ...combinedFilters);
    return absenceForPeriodSplitIntoDays(minDate, maxDate, permissionState, employeeTeamDepartments, filteredAbsences);
  }
);

const mapDay = (
  day,
  timesheets,
  schedules,
  absence,
  canViewSalary,
  rowFilter,
  timesheetsForOverlapCheck
): EmployeeTimesheetDays => {
  const dayTimesheets = timesheets[day] || [];
  const daySchedules = schedules[day] || [];
  const dayAbsence = absence[day] || [];

  const trackedHours = sumTimesheets(dayTimesheets);
  const scheduledHours = sumSchedules(daySchedules);
  const scheduleDiff = trackedHours.hours - scheduledHours.hours;

  const rows = mapGroupedTimesheetData(dayTimesheets, daySchedules, dayAbsence, rowFilter, timesheetsForOverlapCheck)
    //filter out all rows that should be filtered
    .filter((row) => row.keep);

  const filteredTimesheets = rows
    .filter((row) => row.type === 'timesheet')
    .map((row: TimesheetRowTimesheet) => row.timesheet);

  const filteredAbsence = rows
    .filter((row) => row.type === 'absentee')
    .map((row: TimesheetRowAbsentee) => row.absentee);

  const shiftCount = filteredTimesheets.length;

  const total = totalAccumulator(sumTimesheets(filteredTimesheets), sumPeriodAbsence(filteredAbsence));

  if (!canViewSalary) {
    total.pay = 0;
  }

  return { day, rows, total, shiftCount, scheduleDiff };
};

export const getDataForEmployeeTimesheetPage = createSelector(
  getTimesheetEmployeeId,
  getTimesheetMinDate,
  getTimesheetMaxDate,
  getTimesheetsForEmployeeTimesheetPage,
  getSchedulesForEmployeeTimesheetPage,
  getAbsenceForEmployeeTimesheetPage,
  getDaylogsForTimesheet,
  getPermissionState,
  getEmployeeTeamDepartments,
  getTimesheetRowFilter,
  getTimesheetsForTimesheetPageWithoutDepartmentFilter,
  (
    employeeId,
    minDate,
    maxDate,
    timesheets,
    schedules,
    groupedAbsence,
    daylogs,
    permissionState,
    employeeTeamDepartments,
    rowFilter,
    timesheetsForOverlapCheck
  ): EmployeeTimesheetData => {
    const days = dayList(minDate, maxDate);
    const enhancedTimesheets = enhanceTimesheetsWithLogAndPermissions(timesheets, daylogs, permissionState);
    const employeeDepartments = employeeTeamDepartments[employeeId] || null;

    const canViewSalary = hasPermission(
      {
        permissions: ['View salary', 'View own salary'],
        userId: employeeId,
        departments: employeeDepartments,
      },
      permissionState
    );

    const groupedTimesheets = groupBy(enhancedTimesheets, 'date');
    const groupedSchedules = groupBy(schedules, 'date');

    const mappedDates = days
      .map((day) => mapDay(
          day,
          groupedTimesheets,
          groupedSchedules,
          groupedAbsence,
          canViewSalary,
          rowFilter,
          timesheetsForOverlapCheck
        ))
      .filter((dayData) => dayData.rows.length !== 0);

    const shiftCount = mappedDates.reduce((acc, dayData) => acc + dayData.shiftCount, 0);

    const grandTotal = mappedDates.reduce((acc, current) => totalAccumulator(acc, current.total), defaultTotal);

    return {
      days: mappedDates,
      total: grandTotal,
      shiftCount,
      canViewSalary,
    };
  }
);
