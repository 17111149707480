import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, numberAttribute, OnChanges, Output, SimpleChanges } from '@angular/core';
import { AbsenceOptionUnit } from '@app/enums';
import { DecimalToDurationFormatPipe, DurationFormatOptions } from '@app/pipes/decimal-to-duration-format.pipe';
import { Balance, CorrectionItem } from '@app/reducers/orm/employee/employee.model';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { IconComponent } from '@sb/ui';

import { EmployeeOverviewData } from '../../employee-absence-overview.component';
import { LocaleDatePipe } from './../../../../../../pipes/locale-date.pipe';
import {
  CorrectionType,
  PartialBalance,
  PartialBalanceItem,
  TimeOffPartialBalanceComponent,
} from './time-off-partial-balance/time-off-partial-balance.component';

@Component({
  selector: 'time-off-balance',
  templateUrl: './time-off-balance.component.html',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    IconComponent,
    TimeOffPartialBalanceComponent,
    LocaleDatePipe,
    DecimalToDurationFormatPipe,
  ],
  providers: [LocaleDatePipe, DecimalToDurationFormatPipe],
})
export class TimeOffBalanceComponent implements OnChanges {
  @Input()
  public balance: Balance;

  @Input({ transform: numberAttribute })
  public year = new Date().getFullYear();

  @Input()
  public isActive = false;

  @Input()
  public employee: EmployeeOverviewData;

  @Output()
  public toggled = new EventEmitter<void>();

  public partialBalances: PartialBalance[];
  public remainingPercentage = 0;
  public durationOptions: DurationFormatOptions = {
    dayRoundingRule: 'halfDays',
  };

  public constructor(
    private localeDatePipe: LocaleDatePipe,
    private translate: TranslateService,
    private decimalToDurationFormat: DecimalToDurationFormatPipe,
  ) {}

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['balance'] || changes['year']) {
      this.setPartialBalances();
      this.remainingPercentage = this.balance.percentage;
    }
  }

  private setPartialBalances() {
    this.partialBalances = [this.getAccrued(), this.getCorrected(), this.getUsed()];
  }

  private getAccrued(): PartialBalance {
    return {
      balanceId: this.balance.timeOffBalanceId,
      label: this.translate.instant('Accrued'),
      value: this.parseValue(this.balance.accrual.total, false),
      items: [
        {
          label: `${this.translate.instant('Carryover')} ${this.year - 1}`,
          value: this.parseValue(this.balance.accrual.carryover),
          sign: this.getSign(this.balance.accrual.carryover),
        },
        {
          label: this.translate.instant('Accrued'),
          value: this.parseValue(this.balance.accrual.accrued),
          sign: this.getSign(this.balance.accrual.accrued),
        },
        {
          label: this.translate.instant('Forecasted'),
          value: this.parseValue(this.balance.accrual.forecast),
          sign: this.getSign(this.balance.accrual.forecast),
        },
      ],
    };
  }

  private getCorrected(): PartialBalance {
    return {
      balanceId: this.balance.timeOffBalanceId,
      label: this.translate.instant('Corrected'),
      value: this.parseValue(
        this.balance.unit === AbsenceOptionUnit.DAYS
          ? this.balance.corrected.totalDays
          : this.balance.corrected.totalHours,
        false,
      ),
      items: this.correctionsAsPartialBalanceItems(),
      itemsAreCorrections: true,
    };
  }

  private getUsed(): PartialBalance {
    // Used values are always negative
    return {
      balanceId: this.balance.timeOffBalanceId,
      label: this.translate.instant('Used'),
      value: this.parseValue(this.balance.used.total * -1, false),
      items: [
        {
          label: this.translate.instant('Taken'),
          value: this.parseValue(this.balance.used.taken),
          sign: this.balance.used.taken ? '-' : undefined,
        },
        {
          label: this.translate.instant('Pending'),
          value: this.parseValue(this.balance.used.pending),
          sign: this.balance.used.pending ? '-' : undefined,
        },
        {
          label: this.translate.instant('Wait hours'),
          value: this.parseValue(this.balance.used.waitHours),
          sign: this.balance.used.waitHours ? '-' : undefined,
        },
        {
          label: this.translate.instant('Pending wait hours'),
          value: this.parseValue(this.balance.used.pendingWaitHours),
          sign: this.balance.used.pendingWaitHours ? '-' : undefined,
        },
      ],
    };
  }

  private correctionsAsPartialBalanceItems(): PartialBalanceItem[] {
    return this.balance.corrected.corrections.map((correction) => {
      const correctionValue =
        this.balance.unit === AbsenceOptionUnit.DAYS ? correction.correctionDays : correction.correctionHours;

      return {
        label: this.localeDatePipe.transform(correction.date, {
          month: 'short',
          day: 'numeric',
        }),
        value: this.parseValue(correctionValue),
        sign: this.getSign(correctionValue),
        moreDetails:
          this.balance.unit === AbsenceOptionUnit.DAYS && correction.pay
            ? this.parseValue(correction.correctionHours, true, AbsenceOptionUnit.HOURS)
            : null,
        correction: correction,
        correctionType: this.getCorrectionType(correction),
      };
    });
  }

  private getSign(value: number): '+' | '-' | undefined {
    return value === 0 ? undefined : value < 0 ? '-' : '+';
  }

  private parseValue(value: number, removeSign = true, unit?: AbsenceOptionUnit): string {
    return this.decimalToDurationFormat.transform(
      // Sign (+/-) is shown separately in PartialBalanceItem
      removeSign ? Math.abs(value) : value,
      unit || this.balance.unit,
      this.durationOptions,
    );
  }

  private getCorrectionType(correction: CorrectionItem): CorrectionType {
    return correction.pay
      ? CorrectionType.PAYOUT
      : correction.note.includes('Transfer')
        ? CorrectionType.TRANSFER
        : CorrectionType.CORRECTION;
  }
}
