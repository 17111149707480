@if (showName) {
  <div class="mb-1 font-semibold">
    {{ absence.Employee.name }}
  </div>
}

<div class="flex items-center gap-2">
  <sb-badge-icon [absenceBadgeIcon]="absence" class="shrink-0" [size]="'lg'" />

  <div class="flex grow flex-col">
    <span class="font-semibold">
      {{ absence.absenteeOption.option }}
    </span>
    <div class="flex divide-x divide-y-0 divide-solid divide-white text-xs">
      <span class="pr-1 last-of-type:pr-0">
        {{ absence.startdate | localeDate: { weekday: 'short', month: 'short', day: 'numeric' } }}
        @if (absence.open_ended) {
          - <span class="font-bold">{{ 'unknown' | translate }}</span>
        } @else if (absence.startdate !== absence.enddate) {
          - {{ absence.enddate | localeDate: { weekday: 'short', month: 'short', day: 'numeric' } }}
        }
      </span>
      <!-- TODO apparently we only want to show partial day related data when the absence isn't a range -->
      @if (absence.startdate === absence.enddate && absenceDay.partial_day) {
        <span class="pl-1">{{ absenceDay.startTime }} - {{ absenceDay.endTime }}</span>
      }
    </div>
  </div>

  <div class="flex shrink-0 items-center">
    @switch (absence.status) {
      @case ('Approved') {
        <div class="h-2 w-2 rounded-full bg-success-500 pr-1"></div>
        {{ 'Approved' | translate }}
      }
      @case ('Declined') {
        <div class="h-2 w-2 rounded-full bg-error-500 pr-1"></div>
        {{ 'Declined' | translate }}
      }
      @default {
        <div class="h-2 w-2 rounded-full border-2 border-solid border-white pr-1 opacity-25"></div>
        {{ 'Pending' | translate }}
      }
    }
  </div>
</div>

@if (absence.note) {
  <div class="flex w-full items-start gap-2 pt-4">
    <sb-icon class="shrink-0" aria-label="info" [name]="'notepad'" [size]="'lg'" aria-hidden="false"></sb-icon>
    <span class="grow text-sm font-semibold">{{ absence.note }}</span>
  </div>
}
