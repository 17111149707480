import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AutofocusDirective } from '@sb/shared/util';
import { TooltipModule } from '@sb/tooltip';
import {
  BadgeComponent,
  ButtonComponent,
  IconComponent,
  InputDirective,
  PopoverDirective,
  PopoverModule,
} from '@sb/ui';

@Component({
  selector: 'app-timesheet-row-edit-textarea',
  standalone: true,
  imports: [
    CommonModule,
    PopoverModule,
    TooltipModule,
    IconComponent,
    ButtonComponent,
    InputDirective,
    AutofocusDirective,
    BadgeComponent,
    TranslateModule,
    FormsModule,
  ],
  templateUrl: './timesheet-row-edit-textarea.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimesheetRowEditTextareaComponent implements OnChanges {
  private cdr = inject(ChangeDetectorRef);

  @Input()
  public value: string | undefined;
  @Input()
  public isSaving = false;
  @Input()
  public popoverTitle: string | undefined;

  @Output()
  public saved: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild(PopoverDirective, { static: true })
  public popoverDirective: PopoverDirective;

  public inputValue = '';

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['value']?.currentValue !== changes['value']?.previousValue) {
      this.resetValue();
    }
  }

  public onClose(): void {
    this.popoverDirective.close();
    this.resetValue();
  }

  public onSave(): void {
    this.saved.emit(this.inputValue);
    this.popoverDirective.close();
  }

  private resetValue() {
    const value = this.value || '';
    if (this.inputValue !== value) {
      this.inputValue = value;
      this.cdr.detectChanges();
    }
  }
}
