import { createSelector } from '@ngrx/store';
import { AppState } from '../../index';
import { timesheetConflictModelEntityAdapter } from './timesheet-conflict.state';

export const getTimesheetConflictState = (appState: AppState) => appState.orm.timesheetConflicts;

export const { selectAll, selectEntities, selectIds, selectTotal } = timesheetConflictModelEntityAdapter.getSelectors(
  getTimesheetConflictState
);

export const getTimesheetConflictsByTimesheetId = (timesheetId: string) =>
  createSelector(selectEntities, (conflicts) => conflicts[timesheetId]);
