<div
  [ngStyle]="{ 'width.px': widths.status }"
  class="timesheet__item timesheet__status"
  [ngClass]="{
    'timesheet__status--approved': timesheet.status === 'Approved',
    'timesheet__status--declined': timesheet.status === 'Declined',
    'timesheet__status--pending': timesheet.status === 'Pending'
  }"
></div>

<div
  [ngStyle]="{ 'width.px': widths.start }"
  class="timesheet__item timesheet-warning-container"
  [ngClass]="{ warning: form.get('starttime').invalid && form.get('starttime').touched && form.get('starttime').dirty }"
>
  <time-input
    [hideInputAddon]="true"
    name="starttime"
    [formControl]="form.get('starttime')"
    [minuteSteps]="selectedDepartment.timesheet_interval"
  ></time-input>

  <shift-warning
    class="timesheet-warning_icon"
    [deviatesFromSchedule]="deviationsFromSchedule.starttime"
  ></shift-warning>
</div>

<div [ngStyle]="{ 'width.px': widths.end }" class="timesheet__item">--:--</div>

<div *ngIf="cols.break" [ngStyle]="{ 'width.px': widths.break }" class="timesheet__item timesheet-warning-container">
  {{ timesheet.break }}
  <shift-warning class="timesheet-warning_icon" [deviatesFromSchedule]="deviationsFromSchedule.break"></shift-warning>
</div>

<div *ngIf="cols.location" [ngStyle]="{ 'width.px': widths.location }" class="timesheet__item">
  <timesheet-info
    [width]="widths.location"
    [text]="selectedLocation.name"
    [deviatesFromSchedule]="deviationsFromSchedule.location"
  ></timesheet-info>
</div>

<div
  *ngIf="cols.department"
  [ngStyle]="{ 'width.px': widths.department }"
  class="timesheet__item timesheet-warning-container"
  [ngClass]="{
    warning: form.get('department_id').invalid && form.get('department_id').touched && form.get('department_id').dirty
  }"
>
  <select name="departmentId" [formControl]="form.get('department_id')" class="select-small">
    <option value="" disabled [hidden]="form.get('department_id').value !== ''"></option>
    <optgroup *ngFor="let optGroup of departmentOptions" [attr.label]="optGroup.location.name">
      <option *ngFor="let department of optGroup.departments" [value]="department.id" [disabled]="department.isClosed">
        {{ department.name }}
      </option>
    </optgroup>
  </select>

  <shift-warning
    class="timesheet-warning_icon"
    [deviatesFromSchedule]="deviationsFromSchedule.department"
  ></shift-warning>
</div>

<div
  *ngIf="cols.team"
  [ngStyle]="{ 'width.px': widths.team }"
  class="timesheet__item timesheet-warning-container"
  [ngClass]="{ warning: form.get('team_id').invalid }"
>
  <select class="select-small" [formControl]="form.get('team_id')">
    <option value="" disabled [hidden]="form.get('team_id').value !== ''"></option>
    <option *ngFor="let teamOption of teamOptions" [value]="teamOption.value">{{ teamOption.label }}</option>
  </select>

  <shift-warning class="timesheet-warning_icon" [deviatesFromSchedule]="deviationsFromSchedule.team"></shift-warning>
</div>

<div
  *ngIf="cols.shift"
  [ngStyle]="{ 'width.px': widths.shift }"
  class="timesheet__item timesheet-warning-container"
  [ngClass]="{ warning: form.get('shift_id').invalid }"
>
  <select class="select-small" [formControl]="form.get('shift_id')">
    <option value="" disabled [hidden]="form.get('shift_id').value !== ''"></option>
    <option *ngFor="let shiftOption of shiftOptions" [value]="shiftOption.value">{{ shiftOption.label }}</option>
  </select>

  <shift-warning class="timesheet-warning_icon" [deviatesFromSchedule]="deviationsFromSchedule.shift"></shift-warning>
</div>

<div
  *ngIf="cols.surcharge"
  [ngStyle]="{ 'width.px': widths.surcharge }"
  class="timesheet__item"
  [ngClass]="{
    warning: form.get('rate_card_id').invalid && form.get('rate_card_id').touched && form.get('rate_card_id').dirty
  }"
>
  <select
    *ngIf="rateCardOptions && selectedDepartment?.timesheet_surcharges"
    [formControl]="form.get('rate_card_id')"
    class="select-small"
  >
    <option value="" disabled [hidden]="form.get('rate_card_id').value !== ''"></option>
    <option *ngFor="let rateCard of rateCardOptions" [ngValue]="rateCard.value">{{ rateCard.label }}</option>
  </select>

  <ng-container *ngIf="!selectedDepartment?.timesheet_surcharges && selectedRateCard">
    {{ selectedRateCard.name }}
    <span *ngIf="selectedRateCard.deleted">- {{ selectedRateCard.deleted_date | date }}</span>
  </ng-container>
</div>
<div
  *ngIf="cols.km"
  [ngStyle]="{ 'width.px': widths.km }"
  class="timesheet__item"
  [ngClass]="{
    warning: form.get('kilometers').invalid && form.get('kilometers').touched && form.get('kilometers').dirty
  }"
>
  <decimal-input
    *ngIf="selectedDepartment?.km_registration"
    name="kilometers"
    [formControl]="form.get('kilometers')"
  ></decimal-input>

  <ng-container *ngIf="!selectedDepartment?.km_registration">
    {{ form.get('kilometers').value | decimal }}
  </ng-container>
</div>

<div
  *ngIf="cols.meals"
  [ngStyle]="{ 'width.px': widths.meals }"
  class="timesheet__item"
  [ngClass]="{ warning: form.get('meals').invalid && form.get('meals').touched && form.get('meals').dirty }"
>
  <input
    *ngIf="selectedDepartment?.meal_registration"
    name="meals"
    [formControl]="form.get('meals')"
    class="input-small"
    type="number"
    min="0"
  />

  <ng-container *ngIf="!selectedDepartment?.meal_registration">
    {{ form.get('meals').value | decimal }}
  </ng-container>
</div>

<app-timesheet-row-edit-textarea
  *ngIf="cols.note"
  class="timesheet__item"
  [ngStyle]="{ 'width.px': widths.note }"
  [value]="timesheet?.note"
  [isSaving]="timesheet?.saving"
  (saved)="onNoteChange($event)"
></app-timesheet-row-edit-textarea>

<timesheet-row-custom-fields
  [timesheet]="timesheet"
  [canEdit]="true"
  [columns]="customFieldCols"
  [customFields]="customFields"
></timesheet-row-custom-fields>

<div [ngStyle]="{ 'width.px': widths.details }" class="timesheet__item">
  <timesheet-details
    [timesheet]="timesheet"
    [absenceWarning]="absenceWarning"
    [doubleRegistration]="doubleRegistration"
    [cols]="cols"
  ></timesheet-details>
</div>
