<div
  class="grid grow grid-cols-absence-list-row items-stretch gap-2 divide-x divide-y-0 divide-solid divide-grey-200 py-2 font-semibold hover:bg-grey-50"
  [appAbsenceRequest]="{ absenceId: absence.id, isOwnAbsence, originator: 'absence-overview' }"
>
  <div class="flex items-center gap-3 px-2">
    <sb-badge-icon [absenceBadgeIcon]="absence" class="shrink-0" [size]="'lg'" />
    <span class="truncate">{{ absence.absenteeOption.option }}</span>
  </div>
  <div class="flex items-center px-2">
    <span class="truncate">
      {{
        absence.startdate
          | localeDate
            : {
                weekday: 'short',
                month: 'short',
                day: 'numeric'
              }
      }}
      @if (absence.open_ended) {
        - {{ 'unknown' | translate }}
      } @else if (absence.startdate !== absence.enddate) {
        -
        {{
          absence.enddate
            | localeDate
              : {
                  weekday: 'short',
                  month: 'short',
                  day: 'numeric'
                }
        }}
      }
    </span>
  </div>
  <div class="flex items-center px-2">
    <span class="truncate">
      @if (absence.absenteeOption?.unit === AbsenceOptionUnit.DAYS) {
        {{ absence.day_total | decimalToDurationFormat: AbsenceOptionUnit.DAYS }}
      } @else {
        {{ absence.hours | decimalToDurationFormat: AbsenceOptionUnit.HOURS }}
      }
    </span>
  </div>
  <div class="flex items-center justify-end px-2">
    @if (absence.note) {
      <sb-icon
        aria-label="info"
        [name]="'notepad'"
        class="mx-2"
        [sbTooltip]="absence.note"
        [sbTooltipPlacement]="'left'"
        aria-hidden="false"
      >
      </sb-icon>
    }
    @if (absence.canEdit) {
      <button
        sb-icon-button
        class="mx-2"
        [color]="'transparent'"
        [attr.aria-label]="'Edit absence' | translate"
        [appAbsenceRequest]="{ absenceId: absence.id, isOwnAbsence, action: 'edit', originator: 'absence-overview' }"
        (click)="$any($event).stopPropagation()"
      >
        <sb-icon [name]="'pen'"></sb-icon>
      </button>
    }
    @if (absence.canApprove) {
      <sb-status-button
        [status]="absence.status"
        [saving]="absence.saving"
        (click)="$any($event).stopPropagation()"
        (statusChange)="changeStatus(absence, $event)"
      ></sb-status-button>
    } @else {
      <sb-status-icon [status]="absence.status"></sb-status-icon>
    }
  </div>
</div>
