import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { CustomFieldsModel, CustomFieldsType } from '@reducers/orm/custom-fields/custom-fields.model';
import { CustomFieldsService } from '@reducers/orm/custom-fields/custom-fields.service';
import { TimesheetModel } from '@reducers/orm/timesheet/timesheet.model';
import { TimesheetService } from '@reducers/orm/timesheet/timesheet.service';
import { TimesheetCustomFieldsColumns } from '@reducers/page-filters/page-filters.model';
import { convertArrayToObject } from '@reducers/shared/entity.helper';
import isEqual from 'lodash-es/isEqual';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, filter, switchMap } from 'rxjs/operators';

@Component({
  selector: 'timesheet-row-custom-fields',
  templateUrl: './timesheet-row-custom-fields.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimesheetRowCustomFieldsComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  public timesheet: TimesheetModel;
  @Input()
  public customFields: CustomFieldsModel[] = [];
  @Input()
  public canEdit: boolean;
  @Input()
  public columns: TimesheetCustomFieldsColumns;

  public form = new UntypedFormGroup({});
  public mappedCustomFields: any[] = [];
  public customFieldType = CustomFieldsType;
  private dataSubs = new Subscription();

  public constructor(
    private timesheetService: TimesheetService,
    private customFieldsService: CustomFieldsService,
  ) {}

  public ngOnInit() {
    const mapping = this.customFieldsService.createCustomFieldMapping(this.customFields, this.timesheet.custom_fields, [
      CustomFieldsType.TEXT,
      CustomFieldsType.NUMERIC,
      CustomFieldsType.DECIMAL,
      CustomFieldsType.MONEY,
      CustomFieldsType.MULTI_SELECT,
    ]);
    this.mappedCustomFields = mapping.mappedCustomFields;
    this.form.addControl('customField', mapping.formArray);
    this.listenForChanges();
  }

  private listenForChanges() {
    this.dataSubs.add(
      this.form.valueChanges
        .pipe(
          filter(() => this.form.valid && this.canEdit),
          distinctUntilChanged((prev, current) => {
            if (!prev || !current) {
              return false;
            }

            return isEqual(prev, current);
          }),
          switchMap(() => this.saveTimesheet()),
        )
        .subscribe(),
    );
  }

  public ngOnDestroy(): void {
    this.dataSubs.unsubscribe();
  }

  public onCustomFieldChange(value: string, form: AbstractControl) {
    form.get('value').setValue(value);
  }

  private saveTimesheet() {
    if (!this.form.valid) {
      return;
    }

    const timesheetToUpdate = {
      id: this.timesheet.id,
      custom_fields: this.customFieldsService.mapFormDataToRequestData(this.form.get('customField')),
    };

    return this.timesheetService.save(timesheetToUpdate);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    const changed = !isEqual(
      changes.timesheet?.currentValue?.custom_fields,
      changes.timesheet?.previousValue?.custom_fields,
    );
    if (!changed) {
      return;
    }
    if (!changes.timesheet || !this.form.contains('customField')) {
      return;
    }

    const newCustomFieldEntities = convertArrayToObject(
      'id',
      Object.entries(this.timesheet.custom_fields).map(([id, value]) => ({ id, value })),
    );
    const updatedCustomFields = this.form.get('customField')?.value?.map((customField) => ({
      id: customField.id,
      value: newCustomFieldEntities[customField.id].value,
    }));

    this.form.patchValue(
      {
        customField: updatedCustomFields,
      },
      {
        emitEvent: false,
      },
    );
  }
}
