<div
  *ngIf="visible"
  [class.timesheet-grid]="last"
  [ngClass]="{ italic: !!teamId && !employee.Team.includes(teamId) }"
  class="pl-3 timesheet-user-block"
  [ngStyle]="{ 'height.px': height }"
>
  <div *ngIf="show" class="avatar-item">
    <div class="mr-2.5 flex items-center">
      <sb-avatar [employee]="employee" [size]="30"></sb-avatar>
    </div>

    <div class="avatar-item__details" [style.width.px]="160">
      <div class="truncate text-base font-semibold">
        <a
          [ngClass]="{ 'pointer-events-none': !canViewEmployee }"
          [routerLink]="['/employees', employee.id, 'timesheet']"
          [sbTooltip]="'Loaned' | translate"
          [sbTooltipDisabled]="!teamId || employee.Team.includes(teamId)"
        >
          {{ employee.name }}
        </a>
      </div>

      <div class="timesheet-totals">
        <div class="timesheet-totals__time" [sbTooltipPlacement]="'top'" [sbTooltip]="'Approved hours' | translate">
          <small data-testid="timesheet-employee-total-time">{{ total.hours | decimalToTime }}</small>
        </div>
        <div
          *ngIf="showSalary"
          class="timesheet-totals__salary"
          [sbTooltipPlacement]="'top'"
          [sbTooltip]="'Worked - Scheduled' | translate"
        >
          <ng-container *ngIf="scheduleDiff !== 0">
            <small data-testid="timesheet-employee-schedule-diff"
              ><span>{{ scheduleDiff | decimalToTime }}</span></small
            >
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
