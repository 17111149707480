import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { PermissionDirective } from '@app/+authenticated/shared/permission.directive';
import { CorrectionItem } from '@app/reducers/orm/employee/employee.model';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent, IconComponent } from '@sb/ui';

import { EmployeeOverviewData } from '../../../employee-absence-overview.component';
import { TimeOffPartialBalanceItemComponent } from './time-off-partial-balance-item/time-off-partial-balance-item.component';

export enum CorrectionType {
  CORRECTION = 'Correction',
  PAYOUT = 'Payout',
  TRANSFER = 'Transfer',
}

export interface PartialBalanceItem {
  label: string;
  value: string;
  sign?: '+' | '-';
  moreDetails?: string;
  correction?: CorrectionItem;
  correctionType?: CorrectionType;
}

export interface PartialBalance {
  balanceId: string;
  label: string;
  value: string;
  items: PartialBalanceItem[];
  itemsAreCorrections?: boolean;
}

@Component({
  selector: 'time-off-partial-balance',
  templateUrl: './time-off-partial-balance.component.html',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ButtonComponent,
    IconComponent,
    TimeOffPartialBalanceItemComponent,
    PermissionDirective,
    RouterLink,
  ],
})
export class TimeOffPartialBalanceComponent {
  @Input()
  public partial: PartialBalance;

  @Input()
  public employee: EmployeeOverviewData;

  public isActive = false;
}
