import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ScheduleResizeService {
  public isResizing$ = new BehaviorSubject<boolean>(false);

  constructor() {}

  public setResizing(isResizing: boolean) {
    this.isResizing$.next(isResizing);
  }
}
