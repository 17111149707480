import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'timesheet-info',
  templateUrl: './timesheet-info.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimesheetInfoComponent implements OnChanges {
  @Input()
  public width: number;
  @Input()
  public text;
  @Input()
  public absenceWarning: boolean;
  @Input()
  public doubleRegistration: boolean;
  @Input()
  public deviatesFromSchedule: boolean;

  public isTruncated = false;

  public constructor(
    private elementRef: ElementRef,
    private cd: ChangeDetectorRef,
  ) {}

  public ngOnChanges() {
    // Give the .timesheet-info time to render otherwise the widths are not set.
    setTimeout(() => {
      this.checkIfTruncated();
    }, 200);
  }

  private checkIfTruncated() {
    const info = this.elementRef.nativeElement.querySelector('.timesheet-info');

    const tolerance = 2;
    const isTruncated = info.offsetWidth + tolerance < info.scrollWidth;

    if (this.isTruncated !== isTruncated) {
      this.isTruncated = isTruncated;
      this.cd.markForCheck();
    }
  }
}
