import {
  AfterContentChecked,
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  QueryList,
  SimpleChanges,
  ViewRef,
} from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { ElementInViewportService } from '../../../shared/element-in-viewport.service';
import { AwesomeGridService } from './awesome-grid.service';
import { Cell } from './interfaces';

@Component({
  selector: 'awesome-grid-row',
  templateUrl: './awesome-grid-row.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AwesomeGridRowComponent implements AfterContentInit, OnChanges, OnDestroy, AfterContentChecked {
  @Input()
  alwaysVisible = false;

  @ContentChildren(Cell)
  cells: QueryList<Cell>;

  @Input()
  height = 36;

  private detectChanges = new Subject();
  private dataSubs = new Subscription();

  constructor(
    public awesomeGridService: AwesomeGridService,
    private element: ElementRef,
    private elementInViewportService: ElementInViewportService,
    private cd: ChangeDetectorRef,
  ) {
    this.cd.detach();

    this.dataSubs.add(
      this.detectChanges.subscribe(() => {
        if (this.cd && !(this.cd as ViewRef).destroyed) {
          this.cd.detectChanges();
        }
      }),
    );

    this.dataSubs.add(
      this.elementInViewportService
        .getOutSideScopeSubject()
        .pipe()
        .subscribe(() => {
          this.detectChanges.next(null);
        }),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.detectChanges.next(null);
  }

  ngAfterContentInit() {
    // this.validateCells();
    this.setCellVisibility(true);
    this.detectChanges.next(null);
  }

  ngAfterContentChecked() {
    this.detectChanges.next(null);
  }

  ngOnDestroy() {
    this.dataSubs.unsubscribe();
  }

  private validateCells() {
    const cells: Cell[] = this.cells.toArray();
    if (!environment.production) {
      /*
        Note: there are always three Cell's even when not displaying the right column.
        ContentChildren seems to grab invisible children as well.
      */
      if (cells.length !== 3) {
        console.warn(`AwesomeGridRowComponent: detected a row with ${cells.length} number of cells, it should be 3!`);
      }
    }

    if (!this.awesomeGridService.showRight) {
      cells.pop();
    }
  }

  private setCellVisibility(visible: boolean) {
    // Make the cells visible now that we have correctly set `inViewPort`.
    const cells: Cell[] = this.cells.toArray();
    cells.forEach((cell) => {
      cell.setVisible(visible);
    });
  }
}
