<div class="timesheet__totals flex justify-end bg-transparent">
  <div class="timesheet-totals w-[150px]" [ngClass]="{ 'timesheet-totals__row': totalRow }">
    <div class="timesheet-totals__time" data-testid="timesheet-total-time">{{ time | decimalToTime }}</div>
    <ng-container *subscriptionPlan="planType.BASIC">
      @if (showSalary) {
        <div class="timesheet-totals__salary" data-testid="timesheet-total-salary">
          {{ salary | money }}
        </div>
      }
    </ng-container>
  </div>
</div>
