<sb-calendar-day
  class="relative font-medium"
  [ngClass]="classes"
  [ngStyle]="styles"
  [sbTooltip]="tooltipTemplate"
  [sbTooltipMaxWidth]="'30rem'"
  [sbTooltipDisabled]="!absences.length"
>
  <span
    [ngClass]="{
      'underline underline-offset-2 decoration-2': day.isToday
    }"
  >
    {{ day.monthDay }}
  </span>
  @if (absences.length > 1) {
    <div
      class="absolute -bottom-1.5 -right-1.5 box-content flex h-3 w-3 items-center justify-center rounded-full border-2 border-solid border-white bg-word-mark-800 text-xs text-white"
    >
      {{ absences.length }}
    </div>
  }
</sb-calendar-day>

<ng-template #tooltipTemplate>
  <absence-tooltip [absences]="absences" [date]="day.date" />
</ng-template>
