import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { EnhancedAbsenceModel } from '@app/reducers/orm/absence/absence.model';

import { AbsenceTooltipItemComponent } from './absence-tooltip-item.component';

@Component({
  standalone: true,
  selector: 'absence-tooltip',
  imports: [CommonModule, AbsenceTooltipItemComponent],
  templateUrl: './absence-tooltip.component.html',
})
export class AbsenceTooltipComponent {
  @Input({ required: true })
  public absences: EnhancedAbsenceModel[];

  @Input({ required: true })
  public date: Date | string;

  @Input()
  public showName: boolean;
}
