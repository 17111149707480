import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { getAccountSubscription } from '@reducers/account/account.service';
import { AppState } from '@reducers/index';
import { CustomFieldsAppliesTo, CustomFieldsModel } from '@reducers/orm/custom-fields/custom-fields.model';
import { getCustomFieldsByAppliesTo } from '@reducers/orm/custom-fields/custom-fields.selectors';
import {
  TimesheetColumns,
  TimesheetCustomFieldsColumns,
  TimesheetShowFilters,
  TimesheetStatusFilters,
  TimesheetTypeFilters,
} from '@reducers/page-filters/page-filters.model';
import { filterPersistenceDebounceTime, PageFiltersService } from '@reducers/page-filters/page-filters.service';
import { Subscription } from 'rxjs';
import { debounceTime, filter, take } from 'rxjs/operators';

import { hasAtleastSubscriptionPlan } from '../../../../../shared/subscription-plan/subscription-plan.directive';
import { PlanType } from '../../../../+reports/shared/subscriptions/subscription.model';
import { SidebarComponent } from '../../../../shared/sidebar/sidebar.component';

@Component({
  selector: 'timesheet-sidebar',
  templateUrl: './timesheet-sidebar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimesheetSidebarComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  public period: string;
  @Input()
  public columns: TimesheetColumns;
  @Input()
  public show: TimesheetShowFilters;
  @Input()
  public status: TimesheetStatusFilters;
  @Input()
  public type: TimesheetTypeFilters;
  @Input()
  public customFieldsColumns: TimesheetCustomFieldsColumns;

  @Input()
  public periodPage = false;

  public form = new UntypedFormGroup({
    show: new UntypedFormGroup({
      absence: new UntypedFormControl(false),
      schedule: new UntypedFormControl(false),
      employee: new UntypedFormControl(false),
    }),
    status: new UntypedFormGroup({
      approved: new UntypedFormControl(false),
      pending: new UntypedFormControl(false),
      declined: new UntypedFormControl(false),
    }),
    type: new UntypedFormGroup({
      activeClock: new UntypedFormControl(false),
      clocked: new UntypedFormControl(false),
      manual: new UntypedFormControl(false),
      onlyConflict: new UntypedFormControl(false),
    }),
    columns: new UntypedFormGroup({
      break: new UntypedFormControl(false),
      location: new UntypedFormControl(false),
      department: new UntypedFormControl(false),
      team: new UntypedFormControl(false),
      shift: new UntypedFormControl(false),
      note: new UntypedFormControl(false),
      km: new UntypedFormControl(false),
      meals: new UntypedFormControl(false),
      surcharge: new UntypedFormControl(false),
    }),
  });

  public readonly showOptions = [
    { field: 'schedule', label: this.translate.instant('Show schedules'), showOnPeriod: false },
    { field: 'absence', label: this.translate.instant('Show absence'), showOnPeriod: true },
    { field: 'employee', label: this.translate.instant('Show all employees'), showOnPeriod: true },
  ];

  public readonly statusOptions = [
    { field: 'approved', label: this.translate.instant('Approved') },
    { field: 'pending', label: this.translate.instant('Pending') },
    { field: 'declined', label: this.translate.instant('Declined') },
  ];

  public readonly typeOptions = [{ field: 'manual', label: this.translate.instant('Other') }];

  public readonly conflictOptions = [{ field: 'onlyConflict', label: this.translate.instant('Only show conflicts') }];

  public readonly columnOptions = [
    { field: 'break', label: this.translate.instant('Break') },
    { field: 'location', label: this.translate.instant('Location') },
    { field: 'department', label: this.translate.instant('Department') },
    { field: 'team', label: this.translate.instant('Team') },
    { field: 'shift', label: this.translate.instant('Shift') },
    { field: 'note', label: this.translate.instant('Note') },
  ];

  public customFieldOptions = [];

  private subscription = new Subscription();

  public constructor(
    private sidebar: SidebarComponent,
    private filterService: PageFiltersService,
    private translate: TranslateService,
    private store: Store<AppState>,
  ) {}

  public ngOnInit() {
    this.subscription.add(
      this.form.valueChanges.pipe(debounceTime(filterPersistenceDebounceTime)).subscribe((formValue) => {
        this.filterService.setFilters('timesheet', formValue);
      }),
    );

    this.subscription.add(
      this.store
        .pipe(
          select(getAccountSubscription),
          filter((subscription) => !!subscription),
          take(1),
        )
        .subscribe((accountSubscription) => {
          if (hasAtleastSubscriptionPlan(PlanType.BASIC, accountSubscription)) {
            this.columnOptions.push({ field: 'surcharge', label: this.translate.instant('Rate card') });
            this.columnOptions.push({ field: 'km', label: this.translate.instant('Km') });
            this.columnOptions.push({ field: 'meals', label: this.translate.instant('Meals') });
            this.typeOptions.push({ field: 'activeClock', label: this.translate.instant('Currently clocked in') });
            this.typeOptions.push({ field: 'clocked', label: this.translate.instant('Clocked hours') });
          }
        }),
    );

    this.subscription.add(
      this.store
        .select(getCustomFieldsByAppliesTo(CustomFieldsAppliesTo.TIMESHEET))
        .subscribe((customFields: CustomFieldsModel[]) => {
          this.setupCustomFieldOptions(customFields);
        }),
    );
  }

  private setupCustomFieldOptions(customFields: CustomFieldsModel[]) {
    const customFieldOptions = [];
    const formGroup = new UntypedFormGroup({});
    customFields.forEach((customField) => {
      customFieldOptions.push({ field: customField.id.toString(), label: customField.name });
      formGroup.addControl(customField.id.toString(), new UntypedFormControl(false));
    });

    if (this.form.contains('customFields')) {
      this.form.removeControl('customFields');
    }

    if (this.customFieldsColumns) {
      formGroup.patchValue(this.customFieldsColumns, { emitEvent: false });
    }
    this.form.addControl('customFields', formGroup);
    this.customFieldOptions = customFieldOptions;
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['columns']) {
      this.form.get('columns').patchValue(changes['columns'].currentValue, { emitEvent: false });
    }

    if (changes['show']) {
      this.form.get('show').patchValue(changes['show'].currentValue, { emitEvent: false });
    }

    if (changes['type']) {
      this.form.get('type').patchValue(changes['type'].currentValue, { emitEvent: false });
    }

    if (changes['status']) {
      this.form.get('status').patchValue(changes['status'].currentValue, { emitEvent: false });
    }

    if (changes['customFieldsColumns'] && this.form.contains('customFields')) {
      this.form.get('customFields').patchValue(changes['customFieldsColumns'].currentValue);
    }
  }

  public close() {
    this.sidebar.close();
  }
}
