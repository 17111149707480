<ng-container *ngIf="!data">
  <grid-center>
    <content-state [isLoading]="true"> </content-state>
  </grid-center>
</ng-container>

<ng-container *ngIf="data">
  <div
    class="mb-5 flex h-14 z-10 items-center justify-between border-y-0 border-r-0 border-b border-l border-solid border-grey-100 bg-white px-5"
  >
    <div>
      <span class="myschedule-header__date font-bold text-word-mark-800">{{
        selectedViewMonth | date: 'MMMM yyyy'
      }}</span>
    </div>
    <div class="flex-grow flex justify-center">
      <date-pager (dateChange)="changeDate($event)" periodType="month" [date]="date" [placement]="'bottom right'">
      </date-pager>
    </div>
    <div class="flex gap-2">
      @if ((hasBasic$ | async) || (canAddShifts$ | async)) {
        <button [sbDropdownToggle]="menu" [sbDropdownPlacement]="'bottom-left'" sb-button>
          <sb-icon [name]="'ellipsis-v'"></sb-icon>
          {{ 'Actions' | translate }}
        </button>
        <ng-template #menu>
          <div sbDropdownMenu>
            @if (canAddShifts$ | async) {
              <button sbDropdownItem (sbDropdownItemTriggered)="onAddShift()">
                {{ 'Add shift' | translate }}
              </button>
            }
            @if (hasBasic$ | async) {
              <button sbDropdownItemIcon (sbDropdownItemTriggered)="onCalendarSync()">
                <div class="flex flex-col">
                  <span>{{ 'Add to calendar' | translate }}</span>
                  <span class="mb-0 max-w-60 font-light">{{
                    'Subscribe to Shiftbase events with your personal calendar' | translate
                  }}</span>
                </div>
              </button>
            }
          </div>
        </ng-template>
      }
      @if (('tmp_add_availability_button_my_schedule' | featureFlag) && firstEditableWeek) {
        <button
          *subscriptionPlan="planType.EARLY_ADOPTER"
          sb-button
          [color]="'primary'"
          (click)="onEditAvailability(firstEditableWeek, data)"
        >
          <sb-icon [name]="'plus'"></sb-icon>
          {{ 'Add availability' | translate }}
        </button>
      }
    </div>
  </div>
  <div class="page__content flex-col flex px-[22px]">
    <div style="overflow-x: auto" class="mb-05">
      <div class="personal-schedule-calendar">
        <div class="personal-schedule-header">
          <div>{{ 'Mo' | translate }}</div>
          <div>{{ 'Tu' | translate }}</div>
          <div>{{ 'We' | translate }}</div>
          <div>{{ 'Th' | translate }}</div>
          <div>{{ 'Fr' | translate }}</div>
          <div>{{ 'Sa' | translate }}</div>
          <div>{{ 'So' | translate }}</div>
          <ng-container *subscriptionPlan="planType.BASIC">
            <div class="personal-schedule-availability" *ngIf="data.showAvailability">
              {{ 'Availability' | translate }}
            </div>
          </ng-container>
        </div>
        <div *ngFor="let week of data.weeks" class="personal-schedule-week">
          <div
            class="personal-schedule-day"
            *ngFor="let day of week.days"
            [ngClass]="{
              'out-of-month': !day.isInSelectedMonth,
              'is-today': day.isToday,
              'is-past': day.isInPast
            }"
          >
            <div class="cell-top">
              <div class="cell-top__left">
                <ng-container *subscriptionPlan="planType.BASIC">
                  <ng-container *ngFor="let available of day.availabilities">
                    <div
                      *ngIf="available.type"
                      (click)="onEditAvailability(week, data)"
                      class="ab-tl rounded-full pl-1"
                      [ngClass]="{
                        'bg-success-100':
                          available.type === AvailabilityType.AVAILABLE_ALL_DAY ||
                          available.type === AvailabilityType.AVAILABLE_FROM,
                        'bg-grey-300':
                          available.type === AvailabilityType.UNAVAILABLE_ALL_DAY ||
                          available.type === AvailabilityType.UNAVAILABLE_FROM,
                        'ab-tl__dark': available.color_is_dark,
                        'ab-tl__light': !available.color_is_dark
                      }"
                      [sbTooltip]="tooltipTpl"
                    >
                      <div class="ab-tl__static">
                        <div class="ab-tl__static--content">
                          <div class="flex w-full items-center justify-between truncate">
                            <span *ngIf="available.type === 'Available from'" class="av-tl__static--availability-type"
                              >{{ available.starttime | time }} - {{ available.endtime | time }}
                              {{ 'Available' | translate }}</span
                            >
                            <span *ngIf="available.type === 'Unavailable from'" class="av-tl__static--availability-type"
                              >{{ available.starttime | time }} - {{ available.endtime | time }}
                              {{ 'Unavailable' | translate }}</span
                            >
                            <span
                              *ngIf="available.type === 'Available all day' || available.type === 'Unavailable all day'"
                              class="av-tl__static--availability-type text-word-mark-800"
                            >
                              {{ available.type | translate }}
                            </span>
                            <icon
                              class="mt-0.5 h-[9px] min-h-[9px] w-[9px] min-w-[9px]"
                              *ngIf="available?.reason"
                              [svgIcon]="'chat'"
                            ></icon>
                          </div>
                        </div>
                      </div>
                    </div>

                    <ng-template #tooltipTpl>
                      <div class="ab-tl__container">
                        <span>{{ available.type | translate }}</span>
                        {{ available.starttime | time }}
                        <ng-container *ngIf="available.endtime">- {{ available.endtime | time }}</ng-container>
                        <div *ngIf="available.reason" class="sh-tl__static--tooltip--reason">
                          {{ available.reason }}
                        </div>
                      </div>
                    </ng-template>
                  </ng-container>
                </ng-container>
              </div>

              <div class="cell-top__right">{{ day.date | date: 'dd' }}</div>
            </div>
            <div class="flex flex-1 flex-col justify-end gap-0.5">
              @if (day.formattedDate) {
                @for (absence of day.absences; track absence.id) {
                  <absence-item [absence]="absence" [date]="day.formattedDate" [compactView]="false" />
                }
              }
              <div
                *ngFor="let shift of day.schedules"
                class="sh-tl personal-schedule-shift rounded border-[2px] border-solid"
                [ngClass]="{
                  'sh-tl__dark': shift.Shift.color ?? '#2399e4' | lightenHex: { forceLuminosity: 97 } | isColorDark,
                  'sh-tl__light': !(shift.Shift.color ?? '#2399e4' | lightenHex: { forceLuminosity: 97 } | isColorDark),
                  'sh-tl__margin': day.schedules?.length > 1
                }"
                [ngStyle]="{
                  backgroundColor: (shift.Shift.color ?? '#2399e4' | lightenHex: { forceLuminosity: 97 }),
                  borderColor: shift.Shift.color ?? '#2399e4'
                }"
                [sbTooltip]="tp"
                [sbTooltipPlacement]="'top'"
              >
                <div class="relative left-0 top-0">
                  <div
                    class="absolute top-[2px] ml-[2px] h-[26px] w-[6px] min-w-[6px] rounded-full"
                    [ngStyle]="{
                      backgroundColor: shift.Shift.color ?? '#2399e4'
                    }"
                  ></div>
                </div>
                <ng-template #tp>
                  <div class="flex flex-col text-left">
                    <p class="m-0">
                      <strong>{{ shift.Shift.long_name }}</strong
                      >&nbsp;{{ shift.starttime | time }}
                      <ng-container *ngIf="!shift.hide_end_time">- {{ shift.endtime | time }}</ng-container>
                    </p>
                    <p class="m-0">
                      <strong>{{ shift.department_name }}</strong
                      >&nbsp;|&nbsp;<strong>{{ shift.Team.name }}</strong>
                    </p>
                    <p class="m-0" *ngIf="shift.description">
                      <strong>{{ 'Description:' | translate }}</strong
                      >&nbsp;
                      <span>{{ shift.description }}</span>
                    </p>
                    <p class="m-0" *ngIf="day.schedules?.length > 1">
                      <span>{{ 'Employee has already been scheduled' | translate }}</span>
                    </p>
                  </div>
                </ng-template>

                <div class="sh-tl__static relative">
                  <div class="sh-tl__static--content relative top-[-1px] ml-3 p-0 pr-2">
                    <div class="sh-tl__static--shift">
                      <span
                        class="sh-tl__static--shift-name overflow-hidden text-ellipsis text-[12px] font-bold text-word-mark-800"
                      >
                        {{ shift.Shift.long_name }}
                      </span>
                      <span class="sh-tl__static--shift-time overflow-hidden text-ellipsis text-xs text-grey-600">
                        {{ shift.starttime | time }}
                        <ng-container *ngIf="!shift.hide_end_time">- {{ shift.endtime | time }}</ng-container>
                      </span>
                    </div>
                    <div *ngIf="shift.Shift.is_task && !shift.loading" class="sh-tl__static--icon p-0">
                      <icon class="sh-tl__btn-icon text-word-mark-800" svgIcon="clipboard"></icon>
                    </div>
                    <ng-container *subscriptionPlan="planType.BASIC">
                      <div *ngIf="shift.Exchange" class="sh-tl__static--icon p-0">
                        <icon class="sh-tl__btn-icon text-word-mark-800" svgIcon="change_shift"></icon>
                      </div>
                    </ng-container>
                  </div>
                  <div class="sh-tl__buttons inset-x-[1px] top-0">
                    <div
                      *ngIf="shift.canEdit"
                      (click)="onEditShift(shift)"
                      class="sh-tl__btn border-[2px] border-solid border-grey-300 hover:bg-grey-300"
                    >
                      <icon class="sh-tl__btn-icon text-grey-500" svgIcon="edit"></icon>
                    </div>
                    <div
                      *ngIf="!shift.canEdit"
                      (click)="onViewShift(shift)"
                      class="sh-tl__btn border-[2px] border-solid border-grey-300 hover:bg-grey-300"
                    >
                      <icon class="sh-tl__btn-icon text-grey-500" svgIcon="zoom"></icon>
                    </div>
                    <div
                      *ngIf="shift.canDelete"
                      (click)="onDeleteShift(shift)"
                      class="sh-tl__btn border-[2px] border-solid border-grey-300 hover:bg-grey-300"
                    >
                      <icon class="sh-tl__btn-icon text-grey-500" svgIcon="delete"></icon>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ng-container *subscriptionPlan="planType.BASIC">
            <div class="personal-schedule-day personal-schedule-availability" *ngIf="data.showAvailability">
              <div class="buttons_container">
                <a
                  *ngIf="week.canEditAvailability"
                  class="btn btn-success button-icon"
                  (click)="onEditAvailability(week, data)"
                >
                  <icon class="button-icon__icon white" svgIcon="ok"></icon>
                </a>
                <a *ngIf="!week.canEditAvailability" class="btn btn-danger button-icon button-icon--not-allowed">
                  <icon class="button-icon__icon white" svgIcon="ban"></icon>
                </a>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-container>
