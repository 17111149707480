import { Observable } from 'rxjs';
import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges,
  Directive,
} from '@angular/core';

export interface GridWidth { left: number; center: number; right: number }

/**
 *      Cells can be rendered inside of an awesome-grid-row on
 *      the left, center or right position.
 *
 *      Have a `setVisible` method which sets the component's
 *      visibility. When `visible` is `false` the component
 *      should not render anything, but it MUST still be the
 *      height it was given via setHeight. If `visible` is `true`
 *      the component should render normally.
 *
 *      This way for performance the <awesome-grid-row> may tell
 *      the Cell not to render anything. The Cell should try to
 *      render as little DOM elements as possible when `visible` is `true`.
 *      The Cell must be invisible by default!
 */
@Directive()
export abstract class Cell implements OnChanges, AfterContentChecked {
  public visible: boolean = true;

  constructor(protected cd: ChangeDetectorRef) {
    this.cd.detach();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.cd.detectChanges();
  }

  public setVisible(visible: boolean) {
    if (this.visible === visible) {
      return;
    }
    this.visible = visible;
    this.updateCellView();
  }

  public updateCellView() {
    this.cd.detectChanges();
  }

  ngAfterContentChecked() {
    this.cd.detectChanges();
  }
}
