import { Component, DestroyRef, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IconComponent } from '@app/+authenticated/shared/icon.component';
import { TimePipe } from '@app/pipes/time.pipe';
import { getEnhancedAbsenceById } from '@app/reducers/orm/absence/absence.service';
import { Store } from '@ngrx/store';
import { AppState } from '@reducers/index';
import { AbsenceModel, AbsenceStatus, EnhancedAbsenceModel } from '@reducers/orm/absence/absence.model';
import { clsx } from 'clsx';
import { take } from 'rxjs/operators';

@Component({
  selector: 'absence-item-content',
  templateUrl: './absence-item-content.component.html',
  standalone: true,
  imports: [IconComponent, TimePipe],
})
export class AbsenceItemContentComponent implements OnChanges {
  @HostBinding('class')
  public class = clsx([
    'flex',
    'h-8.5',
    'max-w-full',
    'items-center',
    'gap-1',
    'rounded-base',
    'border-2',
    'border-solid',
    'px-1',
    'py-0.5',
    'shadow-sm',
  ]);

  @HostBinding('class.text-white')
  public get hasTextWhite() {
    return this.absence.absenteeOption?.color_is_dark && !this.isPending;
  }

  @HostBinding('class.text-grey-800')
  public get hasTextGrey() {
    return !this.hasTextWhite;
  }

  @HostBinding('class.justify-center')
  @Input()
  public compactView: boolean;

  @HostBinding('style.backgroundColor')
  public get backgroundColor() {
    return this.isPending ? 'white' : this.absence.absenteeOption?.color;
  }

  @HostBinding('style.borderColor')
  public get borderColor() {
    return this.absence.absenteeOption?.color;
  }

  @Input({ required: true })
  public set absence(absence: AbsenceModel | EnhancedAbsenceModel) {
    if (absence.absenteeOption) {
      this._absence = {
        ...(absence as EnhancedAbsenceModel),
      };
    } else {
      this.setEnhancedAbsence();
    }
  }
  public get absence(): AbsenceModel | EnhancedAbsenceModel {
    return this._absence;
  }

  @Input({ required: true })
  public date: string;

  @Input()
  public mode: 'team' | 'employee' = 'employee';

  public isPending: boolean;

  private _absence: EnhancedAbsenceModel;

  public constructor(
    private readonly store: Store<AppState>,
    private readonly destroyRef: DestroyRef,
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['absence']) {
      this.isPending = this.absence.status === AbsenceStatus.PENDING;
    }
  }

  private setEnhancedAbsence() {
    void this.store
      .select(getEnhancedAbsenceById(this.absence.id))
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe((absence: EnhancedAbsenceModel) => {
        this._absence = {
          ...absence,
        };
      });
  }
}
