import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ActionConfirmComponent } from '@app/+authenticated/shared/action-confirm.component';
import { IntlFormatType } from '@app/enums';
import { LocaleDatePipe } from '@app/pipes/locale-date.pipe';
import { PermissionCheckPipe } from '@app/pipes/permission-flag.pipe';
import { CorrectionService } from '@app/reducers/orm/correction/correction.service';
import { TranslateModule } from '@ngx-translate/core';
import { sbIcons } from '@sb/svg-icons';
import { TooltipDirective } from '@sb/tooltip';
import { BadgeIconComponent, IconComponent } from '@sb/ui';

import { EmployeeOverviewData } from '../../../../employee-absence-overview.component';
import { CorrectionType, PartialBalanceItem } from '../time-off-partial-balance.component';

@Component({
  selector: 'time-off-partial-balance-item',
  templateUrl: './time-off-partial-balance-item.component.html',
  standalone: true,
  imports: [
    CommonModule,
    IconComponent,
    TooltipDirective,
    TranslateModule,
    BadgeIconComponent,
    LocaleDatePipe,
    ActionConfirmComponent,
  ],
  providers: [LocaleDatePipe, PermissionCheckPipe],
})
export class TimeOffPartialBalanceItemComponent implements OnChanges {
  @Input()
  public item: PartialBalanceItem;

  @Input()
  public employee: EmployeeOverviewData;

  public isActive = false;
  public iconMap: Record<CorrectionType, sbIcons> = {
    [CorrectionType.CORRECTION]: 'exchange-vertical',
    [CorrectionType.PAYOUT]: 'euro-circle',
    [CorrectionType.TRANSFER]: 'exchange-horizontal',
  };
  public intlFormatType = IntlFormatType;

  public canDelete = false;

  public constructor(
    private permissionCheck: PermissionCheckPipe,
    private correctionService: CorrectionService,
  ) {}

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['employee']) {
      this.canDelete = this.permissionCheck.transform(
        'Adjust time off balances',
        this.employee.id,
        this.employee.departments,
      );
    }
  }

  public deleteCorrection(id: string) {
    void this.correctionService.remove(id).subscribe();
  }
}
