@if (departmentsCount > 1) {
  <ng-container *subscriptionPlan="planType.BASIC">
    <sb-select
      class="max-w-44"
      #departmentsDropdown
      [formControl]="departmentCtrl"
      [items]="departmentOptions"
      [displayTree]="true"
      [searchable]="true"
      [collapsable]="true"
      [multiSelect]="true"
      [showSelectAll]="true"
      [showOnly]="true"
      [customToggle]="true"
    >
      <button
        class="max-w-inherit"
        sb-button
        [color]="'secondary'"
        [sbDropdownToggle]="departmentsDropdown.menu"
        (sbDropdownToggleOpened)="isMenuOpened = true"
        (sbDropdownToggleClosed)="isMenuOpened = false"
      >
        <span class="flex items-center gap-1">
          <span class="truncate">{{ buttonLabel }}</span>
          @if (showPill) {
            <span
              class="flex h-5 min-w-5 items-center justify-center rounded-full bg-primary-500 px-0.5 text-xs text-white"
            >
              {{ selectedDepartmentsCount }}
            </span>
          }
        </span>
        <sb-icon [name]="isMenuOpened ? 'chevron-up' : 'chevron-down'" iconPositionEnd></sb-icon>
      </button>
    </sb-select>
  </ng-container>
}
