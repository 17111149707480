<div class="mx-auto box-content flex max-w-5xl flex-col gap-4 px-16 py-3">
  <div class="self-start">
    <select sbInput [ngModel]="selectedYear" (ngModelChange)="selectYear($event)">
      <option *ngFor="let yearOption of years" [value]="yearOption">{{ yearOption }}</option>
    </select>
  </div>
  <div class="flex items-start gap-4">
    <div class="flex grow flex-col gap-10 rounded-base border border-solid border-grey-200 bg-white p-6">
      <absence-calendar
        class="flex justify-center"
        [year]="selectedYear"
        [pendingAbsences]="absencePending"
        [reviewedAbsences]="absenceReviewed"
      ></absence-calendar>
      <absence-list-section
        [loadingAbsences]="loadingAbsence"
        [pendingAbsences]="absencePending"
        [reviewedAbsences]="absenceReviewed"
        [employee]="employee"
        [selectedYear]="selectedYear"
      ></absence-list-section>
    </div>
    <div
      class="flex w-64 shrink-0 flex-col rounded-base border border-solid border-grey-200 bg-white p-4"
      *permission="
        [
          'View time off balances',
          'View own time off balances',
          'View all timesheets',
          'View absentee',
          'View own absentee'
        ];
        user: employee?.id;
        department: employee?.departments
      "
    >
      <time-off-balances
        [year]="selectedYear"
        [employee]="employee"
        *permission="
          ['View time off balances', 'View own time off balances'];
          user: employee?.id;
          department: employee?.departments
        "
      ></time-off-balances>
      <hr
        class="mx-0 my-6 border-solid border-grey-200"
        *permission="
          null;
          groups: [
            ['View time off balances', 'View own time off balances'],
            ['View all timesheets'],
            ['View absentee', 'View own absentee']
          ];
          user: employee?.id;
          department: employee?.departments
        "
      />
      <absence-type-distribution
        [employeeId]="employee.id"
        [year]="selectedYear"
        *permission="
          null;
          groups: [['View all timesheets'], ['View absentee', 'View own absentee']];
          user: employee?.id;
          department: employee?.departments
        "
      ></absence-type-distribution>
    </div>
  </div>
</div>
