<div [sbTooltip]="value || ('Add note' | translate)">
  <sb-badge
    [size]="'large'"
    [color]="'blue'"
    [sbPopover]="notePopover"
    [sbPopoverPosition]="'bottom'"
    [sbPopoverDisabled]="isSaving"
    [ngClass]="{ 'cursor-pointer': !isSaving }"
  >
    <sb-icon [name]="value ? 'comment' : 'plus'"></sb-icon>
  </sb-badge>
</div>
<ng-template #notePopover>
  <sb-popover [title]="popoverTitle || ('Timesheet note' | translate)">
    <textarea
      class="resize-none"
      sbInput
      sbAutofocus
      [(ngModel)]="inputValue"
      [ngModelOptions]="{ standalone: true }"
      rows="4"
      cols="50"
    >
    </textarea>
    <sb-popover-footer>
      <button sb-button [color]="'secondary'" (click)="onClose()">
        {{ 'Close' | translate }}
      </button>
      <button sb-button [color]="'primary'" (click)="onSave()">
        {{ 'Save' | translate }}
      </button>
    </sb-popover-footer>
  </sb-popover>
</ng-template>
