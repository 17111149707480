import { of as observableOf, Observable } from 'rxjs';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, HostBinding, Input } from '@angular/core';
import { Cell } from '../../../../../shared/awesome-grid/interfaces';

@Component({
  selector: 'timesheet-day-header',
  templateUrl: './timesheet-day-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: Cell, useExisting: forwardRef(() => TimesheetDayHeaderComponent) }],
})
export class TimesheetDayHeaderComponent extends Cell {
  @Input()
  title: string;
  @Input()
  width: number;
  @Input()
  class: string = '';
  @Input()
  showAsFooter: boolean;

  @HostBinding('style.height.px')
  public height = 36;

  constructor(cd: ChangeDetectorRef) {
    super(cd);
  }
}
