import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { getAccountSubscription } from '@app/reducers/account/account.service';
import { getPermissionState, hasPermission } from '@app/reducers/auth/permission.helper';
import { AvailabilityType } from '@app/reducers/orm/availability/availability.model';
import { hasAtleastSubscriptionPlan } from '@app/shared/subscription-plan/subscription-plan.directive';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, combineLatest as observableCombineLatest, Subject, takeUntil } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { AppState } from '../../../reducers/index';
import { ScheduleModel } from '../../../reducers/orm/schedule/schedule.model';
import { format, parseDate } from '../../../shared/date.helper';
import { lazySelect } from '../../../shared/lazy-select.observable';
import { PlanType } from '../../+reports/shared/subscriptions/subscription.model';
import { getMyScheduleData } from './personal-schedule.helper';
import { MyScheduleData, MyScheduleWeek } from './personal-schedule.interfaces';

@Component({
  selector: 'personal-schedule',
  templateUrl: './personal-schedule.component.html',
  animations: [
    trigger('fadeInOut', [
      state('in', style({ opacity: 1, transform: 'translateX(-50%) translateY(0)' })),
      transition(':enter', [style({ opacity: 0, transform: 'translateX(-50%) translateY(-10px)' }), animate('125ms')]),
      transition(':leave', [animate('125ms', style({ opacity: 0, transform: 'translateX(-50%) translateY(-10px)' }))]),
    ]),
  ],
})
export class PersonalScheduleComponent implements OnInit, OnDestroy {
  public data?: MyScheduleData = null;

  private _date: Date = new Date();
  private dateChange$: BehaviorSubject<Date> = new BehaviorSubject(new Date());

  @Input()
  public employeeId$: Observable<string>;
  public employeeId: string;

  @Input()
  public set date(date: Date) {
    this._date = date;
    this.dateChange$.next(date);
  }

  public get date(): Date {
    return this._date;
  }

  @Output()
  public changeDateEmitter = new EventEmitter<string>();

  public planType = PlanType;

  public AvailabilityType = AvailabilityType;

  public firstEditableWeek: MyScheduleWeek;

  public selectedViewMonth: Date;

  public destroyed$ = new Subject<void>();

  public hasBasic$: Observable<boolean>;
  public canAddShifts$: Observable<boolean>;

  public constructor(
    private store: Store<AppState>,
    private router: Router,
  ) {
    this.hasBasic$ = this.store
      .select(getAccountSubscription)
      .pipe(map((accountSubscription) => hasAtleastSubscriptionPlan(PlanType.BASIC, accountSubscription)));

    this.canAddShifts$ = this.store.select(getPermissionState).pipe(
      map((permissionState) =>
        hasPermission(
          {
            permissions: ['Create roster', 'Create own roster'],
            userId: this.employeeId,
            departments: 'any',
          },
          permissionState,
        ),
      ),
    );
  }

  public ngOnInit() {
    void observableCombineLatest([this.dateChange$, this.employeeId$])
      .pipe(
        switchMap(([date, employeeId]) => {
          this.selectedViewMonth = date;
          this.employeeId = employeeId;

          return this.store.pipe(lazySelect(getMyScheduleData(employeeId, date)));
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe((data) => {
        this.data = data;
        this.firstEditableWeek = data.weeks.find((week) => week.canEditAvailability);
      });
  }

  public ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public changeDate(date: string) {
    this.date = parseDate(date);
    this.changeDateEmitter.emit(date);
  }

  public onEditAvailability(week: MyScheduleWeek, data: MyScheduleData) {
    if (!week.canEditAvailability) {
      return;
    }

    const start = format(week.firstEditableAvailabilityDate.date, 'yyyy-MM-dd');
    const end = format(week.lastDayOfWeek.date, 'yyyy-MM-dd');
    const employee = data.userId;

    const params = { start, end, employee };

    this.router.navigate(['', { outlets: { modal: ['availability', params] } }]);
  }

  public onEditShift(shift: ScheduleModel) {
    this.router.navigate([
      '',
      { outlets: { modal: ['schedule-shift', shift.occurrence_id, 'edit', { showEmployee: false }] } },
    ]);
  }

  public onDeleteShift(shift: ScheduleModel) {
    this.router.navigate(['', { outlets: { modal: ['schedule-shift', shift.occurrence_id, 'delete'] } }]);
  }

  public onViewShift(shift: ScheduleModel) {
    this.router.navigate(['', { outlets: { modal: ['schedule-shift', shift.occurrence_id] } }]);
  }

  public onAddShift() {
    this.router.navigate([
      '',
      { outlets: { modal: ['schedule-shift', { employee: this.employeeId, showEmployee: false }] } },
    ]);
  }

  public onCalendarSync() {
    this.router.navigate(['', { outlets: { modal: ['calendar-sync'] } }]);
  }
}
