import { DateFormatType } from '@app/enums';
import { isAbsenceWithoutValueHidden } from '@app/reducers/orm/absence/absence.helper';
import { addDays, isBefore, isSameDay, isSameMonth, startOfDay, startOfISOWeek, startOfMonth, subDays } from 'date-fns';
import chunk from 'lodash-es/chunk';
import first from 'lodash-es/first';
import last from 'lodash-es/last';
import maxBy from 'lodash-es/maxBy';
import pickBy from 'lodash-es/pickBy';
import { createSelector } from 'reselect';

import { PermissionState } from '../../../reducers/auth/auth.model';
import {
  getEmployeeTeamDepartments,
  getEmployeeTeamDepartmentsWithoutHiddenTeams,
  getPermissionState,
  hasPermission,
} from '../../../reducers/auth/permission.helper';
import { EnhancedAbsenceModel, KeyedAbsenteeDays } from '../../../reducers/orm/absence/absence.model';
import { checkAbsenteePermission } from '../../../reducers/orm/absence/absence.permission.helper';
import { getAbsencesForUser } from '../../../reducers/orm/absence/absence.service';
import { AvailabilityModel } from '../../../reducers/orm/availability/availability.model';
import { getAvailabilitiesForUser } from '../../../reducers/orm/availability/availability.service';
import { DepartmentModel } from '../../../reducers/orm/department/department.model';
import { getDepartmentEntities } from '../../../reducers/orm/department/department.service';
import { ScheduleModel } from '../../../reducers/orm/schedule/schedule.model';
import { getSchedulesForUser, hasSchedulePermission } from '../../../reducers/orm/schedule/schedule.service';
import { dateRange, format, parseDate, rangeList } from '../../../shared/date.helper';
import { DateRange } from './../../../shared/interfaces';
import { MyScheduleData, MyScheduleDay, MyScheduleWeek } from './personal-schedule.interfaces';

export const getMyScheduleData = (userId: string, date: Date) =>
  createSelector(
    getSchedulesForUser(userId),
    getAvailabilitiesForUser(userId),
    getAbsencesForUser(userId),
    getPermissionState,
    getDepartmentsForUser(userId),
    getEmployeeTeamDepartments,
    (
      schedules: ScheduleModel[],
      availabilities: AvailabilityModel[],
      absences: EnhancedAbsenceModel[],
      permissionState: PermissionState,
      userDepartments: DepartmentModel[],
      employeeTeamDepartments,
    ): MyScheduleData => {
      const selectedMyScheduleDate = date ? format(date, 'yyyy-MM-dd') : format(new Date(), 'yyyy-MM-dd');

      const selectedDate = parseDate(selectedMyScheduleDate);
      const dayRange = daysRangeList(selectedMyScheduleDate);

      const canAlwaysEditAvailability = hasEditAvailabilityPermission(userId, permissionState);
      const canEditOwnAvailability = hasEditOwnAvailabilityPermission(userId, permissionState);
      const availabilityLock = getStrictestAvailabilityLock(userDepartments);

      let canEditAvailability = false;

      const days = dayRange.map((dayPeriod: DateRange): MyScheduleDay => {
        const start = dayPeriod.start;
        const formattedDate = format(start, 'yyyy-MM-dd');

        // If you can edit the current `date`, you can edit any future dates as well.
        if (canEditAvailability === false) {
          canEditAvailability =
            canAlwaysEditAvailability ||
            (canEditOwnAvailability && canEditAvailabilityForDateWithLock(dayPeriod.start, availabilityLock));
        }

        return {
          date: start,
          formattedDate: formattedDate,
          isInSelectedMonth: isSameMonth(start, selectedDate),
          isToday: isSameDay(new Date(), start),
          isInPast: isBefore(startOfDay(start), startOfDay(new Date())),
          schedules: getSchedules(schedules, formattedDate, permissionState),
          availabilities: availabilities.filter((availability) => availability.date === formattedDate),
          canEditAvailability,
          absences: getAbsences(absences, dayPeriod.start, employeeTeamDepartments, permissionState),
        };
      });

      const weeks: MyScheduleWeek[] = chunk(days, 7).map((days) => {
        const firstEditiableAvailabilityDay = days.find((day) => day.canEditAvailability);

        return {
          days,
          canEditAvailability: firstEditiableAvailabilityDay !== undefined,
          firstEditableAvailabilityDate: firstEditiableAvailabilityDay,
          lastDayOfWeek: last(days),
        };
      });

      return {
        userId,
        startDate: first(days).date,
        endDate: last(days).date,
        weeks,
        showAvailability: weeks.some((week) => week.canEditAvailability),
      };
    },
  );

export function calculateMyScheduleRange(date: string) {
  // Start on first monday of the month
  const start = startOfISOWeek(startOfMonth(parseDate(date)));

  // Always show 42 days which is 6 weeks * 7 days
  const end = addDays(new Date(start), 41);

  return { start, end };
}

/**
 * Returns whether or not the user can edit the Availability of
 * the supplied `date`.
 *
 * @param {string} availabilityLock The days to lock before today or the start of the current week.
 * @param {Date} date The date you want to check.
 * @returns {boolean}
 */
function canEditAvailabilityForDateWithLock(date: Date, availabilityLock: string) {
  const today = startOfDay(new Date());

  if (availabilityLock === '0') {
    return isSameDay(today, date) || isBefore(startOfDay(today), startOfDay(date));
  } else {
    // We use the first day of the current week as the reference point.
    const firstDayOfCurrentWeek = startOfISOWeek(date);
    const subtracted = subDays(firstDayOfCurrentWeek, parseInt(availabilityLock, 10));

    return isSameDay(subtracted, today) || isBefore(today, subtracted);
  }
}

function hasEditAvailabilityPermission(userId: string, permissionState: PermissionState) {
  return hasPermission(
    {
      permissions: ['Edit availability'],
      userId: userId,
      departments: 'any',
    },
    permissionState,
  );
}

function hasEditOwnAvailabilityPermission(userId: string, permissionState: PermissionState) {
  return hasPermission(
    {
      permissions: ['Edit own availability'],
      userId: userId,
      departments: 'any',
    },
    permissionState,
  );
}

function getStrictestAvailabilityLock(userDepartments: DepartmentModel[]) {
  const strictest: DepartmentModel = maxBy(userDepartments, 'lock_availability_days_before_period');

  return strictest ? strictest.lock_availability_days_before_period : '0';
}

function daysRangeList(date: string): DateRange[] {
  const monthRange = calculateMyScheduleRange(date);
  const range = dateRange(monthRange.start, monthRange.end);
  return rangeList(range, 'day');
}

function getAbsences(
  absences: EnhancedAbsenceModel[],
  date: Date,
  employeeTeamDepartments,
  permissionState: PermissionState,
) {
  return absences
    .filter((absence) => {
      // Do not show declined absences.
      if (absence.status === 'Declined') {
        return false;
      }

      const absenceDay = absence.AbsenteeDay[format(date, DateFormatType.DEFAULT)];
      return !!absenceDay;
    })
    .map((absence: EnhancedAbsenceModel) => {
      absence.canEdit = checkAbsenteePermission(absence, employeeTeamDepartments, permissionState, 'edit');

      const daysFilteredForHours = pickBy(absence.AbsenteeDay, (absenteeDay) => {
        if (!isSameDay(absenteeDay.startDate, date) && !isSameDay(absenteeDay.endDate, date)) {
          return false;
        }

        if (isAbsenceWithoutValueHidden(absence, absenteeDay)) {
          return false;
        }

        return true;
      }) as KeyedAbsenteeDays;

      return { ...absence, AbsenteeDay: daysFilteredForHours };
    })
    .filter((absence) => {
      const days = Object.values(absence.AbsenteeDay);
      return days.length > 0;
    });
}

function getSchedules(schedules: ScheduleModel[], date: string, permissionState: PermissionState) {
  const canEditSchedule = hasSchedulePermission(['Edit roster', 'Edit own roster'], permissionState);

  const canDeleteSchedule = hasSchedulePermission(['Delete roster', 'Delete own roster'], permissionState);

  return schedules
    .filter((schedule) => schedule.date === date)
    .map((schedule: ScheduleModel) => {
      schedule.canEdit = canEditSchedule(schedule);
      schedule.canDelete = canDeleteSchedule(schedule);

      return schedule;
    });
}

export const getDepartmentsForUser = (userId: string) =>
  createSelector(
    getEmployeeTeamDepartmentsWithoutHiddenTeams,
    getDepartmentEntities,
    (employeeTeamDepartments, departmentEntities: { [id: string]: DepartmentModel }): DepartmentModel[] => {
      const employeeDepartments = employeeTeamDepartments[userId] || [];

      return employeeDepartments.map((departmentId) => departmentEntities[departmentId]);
    },
  );
