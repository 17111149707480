import { NgModule } from '@angular/core';
import { CalculateTimesheetTotalBreakTimePipe } from '@app/pipes/calculate-timesheet-total-break-time.pipe';
import { AutofocusDirective } from '@sb/shared/util';
import {
  BadgeComponent,
  IconComponent,
  InputDirective,
  PopoverModule,
  StatusButtonComponent,
  StatusIconComponent,
} from '@sb/ui';

import { SharedModule } from '../../../../shared/shared.module';
import { SharedTimesheetModule } from '../../shared/shared-timesheet.module';
import { TimesheetContentRowComponent } from './timesheet-day-cells/timesheet-content-row/timesheet-content-row.component';
import { TimesheetDayHeaderComponent } from './timesheet-day-cells/timesheet-day-header/timesheet-day-header.component';
import { TimesheetDayInfoComponent } from './timesheet-day-cells/timesheet-day-info/timesheet-day-info.component';
import { TimesheetEmployeeComponent } from './timesheet-day-cells/timesheet-employee/timesheet-employee.component';
import { TimesheetGrandTotalComponent } from './timesheet-day-cells/timesheet-grand-total/timesheet-grand-total.component';
import { TimesheetTotalAbsenceComponent } from './timesheet-day-cells/timesheet-total-absence/timesheet-total-absence.component';
import { TimesheetTotalScheduleComponent } from './timesheet-day-cells/timesheet-total-schedule/timesheet-total-schedule.component';
import { TimesheetRowAbsenceComponent } from './timesheet-row/absence/timesheet-row-absence.component';
import { TimesheetRowCustomFieldsComponent } from './timesheet-row/customfields/timesheet-row-custom-fields.component';
import { TimesheetRowScheduleComponent } from './timesheet-row/schedule/timesheet-row-schedule.component';
import { LocationInfoComponent } from './timesheet-row/shared/location-info/location-info.component';
import { TimesheetDetailsComponent } from './timesheet-row/shared/timesheet-details/timesheet-details.component';
import { TimesheetInfoComponent } from './timesheet-row/shared/timesheet-info/timesheet-info.component';
import { TimesheetRowEditTextareaComponent } from './timesheet-row/shared/timesheet-row-edit-textarea/timesheet-row-edit-textarea.component';
import { TimesheetRowClockedInComponent } from './timesheet-row/timesheet-clocked-in/timesheet-row-clocked-in.component';
import { TimesheetRowEditComponent } from './timesheet-row/timesheet-edit/timesheet-row-edit.component';
import { TimesheetRowTimesheetComponent } from './timesheet-row/timesheet/timesheet-row-timesheet.component';
import { TimesheetTotalTimesheetAddComponent } from './timesheet-total/timesheet-total-add/timesheet-total-timesheet-add.component';
import { TimesheetTotalTimesheetClockedInComponent } from './timesheet-total/timesheet-total-timesheet-clocked-in/timesheet-total-timesheet-clocked-in.component';
import { TimesheetTotalTimesheetEditComponent } from './timesheet-total/timesheet-total-timesheet-edit/timesheet-total-timesheet-edit.component';
import { TimesheetTotalTimesheetComponent } from './timesheet-total/timesheet-total-timesheet/timesheet-total-timesheet.component';
import { TimesheetTotalComponent } from './timesheet-total/timesheet-total/timesheet-total.component';

const standalone = [
  TimesheetRowEditTextareaComponent,
  TimesheetTotalTimesheetClockedInComponent,
  TimesheetTotalComponent,
  TimesheetTotalTimesheetComponent,
];

const declarations = [
  TimesheetEmployeeComponent,
  TimesheetGrandTotalComponent,
  TimesheetTotalAbsenceComponent,
  TimesheetTotalScheduleComponent,
  TimesheetTotalTimesheetAddComponent,
  TimesheetTotalTimesheetEditComponent,
  TimesheetDayInfoComponent,
  TimesheetDayHeaderComponent,
  TimesheetContentRowComponent,
  TimesheetRowTimesheetComponent,
  TimesheetRowClockedInComponent,
  TimesheetRowEditComponent,
  TimesheetRowAbsenceComponent,
  TimesheetRowScheduleComponent,
  TimesheetRowCustomFieldsComponent,
  TimesheetDetailsComponent,
  TimesheetInfoComponent,
  LocationInfoComponent,
];

@NgModule({
  imports: [
    SharedModule,
    SharedTimesheetModule,
    BadgeComponent,
    CalculateTimesheetTotalBreakTimePipe,
    PopoverModule,
    InputDirective,
    AutofocusDirective,
    IconComponent,
    StatusButtonComponent,
    StatusIconComponent,
    ...standalone,
  ],
  declarations: [...declarations],
  exports: [...declarations, ...standalone],
})
export class TimesheetDaySharedModule {}
