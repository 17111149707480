import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import {
  AnchorButtonComponent,
  ButtonComponent,
  ButtonGroupComponent,
  EmptyStateComponent,
  IconComponent,
  LoadingStateComponent,
} from '@sb/ui';
import { startOfDay } from 'date-fns';
import { partition } from 'lodash';

import { Features } from '../../../../../enums';
import { FeatureFlagPipe } from '../../../../../pipes/feature-flag.pipe';
import { AbsenceStatus, EnhancedAbsenceModel } from '../../../../../reducers/orm/absence/absence.model';
import { PermissionOption } from '../../../../../reducers/orm/permission/permission.model';
import { parseDate } from '../../../../../shared/date.helper';
import { SubscriptionPlanDirective } from '../../../../../shared/subscription-plan/subscription-plan.directive';
import { FeatureService } from '../../../../../startup/feature.service';
import { PlanType } from '../../../../+reports/shared/subscriptions/subscription.model';
import { PermissionDirective } from '../../../../shared/permission.directive';
import { EmployeeOverviewData } from '../employee-absence-overview.component';
import { AbsenceListComponent } from './absence-list.component';

export enum AbsenceFilter {
  PENDING_OR_OPEN_ENDED = 'PENDING OR APPROVED',
  FUTURE = 'FUTURE',
  PAST = 'PAST',
}

@Component({
  selector: 'absence-list-section',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    RouterLink,
    SubscriptionPlanDirective,
    PermissionDirective,
    AbsenceListComponent,
    ButtonGroupComponent,
    ButtonComponent,
    AnchorButtonComponent,
    IconComponent,
    LoadingStateComponent,
    EmptyStateComponent,
    FeatureFlagPipe,
  ],
  templateUrl: './absence-list-section.component.html',
})
export class AbsenceListSectionComponent implements OnChanges {
  @Input()
  public loadingAbsences: boolean;

  @Input()
  public pendingAbsences: EnhancedAbsenceModel[] = [];

  @Input()
  public reviewedAbsences: EnhancedAbsenceModel[] = [];

  @Input()
  public employee: EmployeeOverviewData;

  @Input()
  public selectedYear: string;

  // TODO why the hell does a generic thing like this live in the 'reports' folder?
  public readonly planType = PlanType;
  public readonly requestPermissions: PermissionOption = ['Create absentee', 'Create own absentee'];
  public readonly Features = Features;

  public absenceFilter: AbsenceFilter = AbsenceFilter.PENDING_OR_OPEN_ENDED;
  public absenceFilterOptions = AbsenceFilter;

  public reviewedPastAbsences: EnhancedAbsenceModel[] = [];
  public reviewedFutureAbsences: EnhancedAbsenceModel[] = [];
  public reviewedOpenEndedAbsences: EnhancedAbsenceModel[] = [];

  public constructor(private readonly featureService: FeatureService) {}

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['reviewedAbsences']) {
      let reviewedAbsences = this.reviewedAbsences;
      if (this.featureService.isFeatureActivated(Features.TMP_OPEN_ENDED_ABSENCES)) {
        // Partition reviewed absences into open ended approved absences and non open ended reviewed absences
        const partitionedAbsences = partition(
          reviewedAbsences,
          (absence) => absence.status === AbsenceStatus.APPROVED && absence?.open_ended === true,
        );
        this.reviewedOpenEndedAbsences = partitionedAbsences[0];
        reviewedAbsences = partitionedAbsences[1];
      }

      const today = startOfDay(new Date());
      this.reviewedFutureAbsences = reviewedAbsences.filter((absence) => parseDate(absence.enddate) >= today);
      this.reviewedPastAbsences = reviewedAbsences.filter((absence) => parseDate(absence.enddate) < today).reverse();
    }
  }

  public get pendingOrOpenEndedCount(): number {
    return this.pendingAbsences.length + this.reviewedOpenEndedAbsences.length;
  }
}
