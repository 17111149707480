<div class="awesome-grid-row" [ngStyle]="{ 'height.px': height }">
  <div class="awesome-grid-row-left" [ngStyle]="{ 'width.px': awesomeGridService.widths?.left, 'height.px': height }">
    <ng-content select="[awesome-grid-left]"></ng-content>
  </div>

  <div class="awesome-grid-row-center" HorizontalScrollable>
    <div
      class="awesome-grid-row-center__container"
      [ngStyle]="{ 'width.px': awesomeGridService.widths?.center, 'height.px': height }"
    >
      <ng-content select="[awesome-grid-center]"></ng-content>
    </div>
  </div>

  <div
    *ngIf="awesomeGridService.showRight"
    class="awesome-grid-row-right"
    [ngStyle]="{ 'width.px': awesomeGridService.widths?.right, 'height.px': height }"
  >
    <ng-content select="[awesome-grid-right]"></ng-content>
  </div>
</div>
