<div *subscriptionPlan="planType.BASIC" class="flex flex-col">
  <h4 class="m-0 text-lg font-bold text-word-mark-800">{{ 'Absence' | translate }}</h4>
  <div class="flex items-center justify-between py-4">
    <sb-button-group>
      <button
        sb-button
        [color]="absenceFilter === absenceFilterOptions.PENDING_OR_OPEN_ENDED ? 'grey' : 'secondary'"
        (click)="absenceFilter = absenceFilterOptions.PENDING_OR_OPEN_ENDED"
      >
        <div class="flex items-center gap-2">
          @if (Features.TMP_OPEN_ENDED_ABSENCES | featureFlag) {
            {{ 'Requests' | translate }}
          } @else {
            {{ 'Pending' | translate }}
          }
          @if (pendingOrOpenEndedCount > 0) {
            <div
              class="flex h-4 min-w-4 items-center justify-center rounded-full bg-grey-400 px-1 text-xs font-bold text-white"
            >
              {{ pendingOrOpenEndedCount }}
            </div>
          }
        </div>
      </button>
      <button
        sb-button
        [color]="absenceFilter === absenceFilterOptions.FUTURE ? 'grey' : 'secondary'"
        (click)="absenceFilter = absenceFilterOptions.FUTURE"
      >
        <div class="flex items-center gap-2">
          {{ 'Reviewed' | translate }}
          @if (reviewedFutureAbsences.length > 0) {
            <div
              class="flex h-4 min-w-4 items-center justify-center rounded-full bg-grey-400 px-1 text-xs font-bold text-white"
            >
              {{ reviewedFutureAbsences.length }}
            </div>
          }
        </div>
      </button>
      <button
        sb-button
        [color]="absenceFilter === absenceFilterOptions.PAST ? 'grey' : 'secondary'"
        (click)="absenceFilter = absenceFilterOptions.PAST"
      >
        {{ 'Past' | translate }}
      </button>
    </sb-button-group>
    <a
      *permission="requestPermissions; department: employee.departments; user: employee.id"
      sb-button
      [color]="'primary'"
      [routerLink]="[
        '',
        { outlets: { modal: ['absentee-days-request', { employee: employee.id, selectedYear: selectedYear }] } }
      ]"
    >
      <sb-icon name="plus"></sb-icon>
      {{ 'Add absence' | translate }}
    </a>
  </div>
  @if (loadingAbsences) {
    <sb-loading-state class="h-40" />
  } @else {
    <ng-container *ngTemplateOutlet="loaded"></ng-container>
  }
</div>

<ng-template #loaded>
  @switch (absenceFilter) {
    @case (absenceFilterOptions.PENDING_OR_OPEN_ENDED) {
      @if (pendingOrOpenEndedCount === 0) {
        <sb-empty-state
          class="h-40 rounded-base border border-solid border-grey-200"
          [icon]="'calendar-month'"
          [description]="
            ((Features.TMP_OPEN_ENDED_ABSENCES | featureFlag) ? 'No requests' : 'No pending requests') | translate
          "
        >
          <button sb-button [color]="'primary'" (click)="absenceFilter = absenceFilterOptions.FUTURE">
            {{ 'Check reviewed absences' | translate }}
          </button>
        </sb-empty-state>
      }
      <div class="flex flex-col gap-4 empty:hidden">
        @if (reviewedOpenEndedAbsences.length > 0) {
          <span class="font-semibold text-word-mark-800">{{ 'End date unknown' | translate }}</span>
          <absence-list [absences]="reviewedOpenEndedAbsences" />
        }
        @if (pendingAbsences.length > 0) {
          @if (Features.TMP_OPEN_ENDED_ABSENCES | featureFlag) {
            <span class="font-semibold text-word-mark-800">{{ 'Pending' | translate }}</span>
          }
          <absence-list [absences]="pendingAbsences" />
        }
      </div>
    }
    @case (absenceFilterOptions.FUTURE) {
      @if (reviewedFutureAbsences.length === 0) {
        <sb-empty-state
          class="h-40 rounded-base border border-solid border-grey-200"
          [icon]="'calendar-month'"
          [description]="'No upcoming absences' | translate"
        />
      } @else {
        <absence-list [absences]="reviewedFutureAbsences" />
      }
    }
    @case (absenceFilterOptions.PAST) {
      @if (reviewedPastAbsences.length === 0) {
        <sb-empty-state
          class="h-40 rounded-base border border-solid border-grey-200"
          [icon]="'calendar-month'"
          [description]="'No past absences' | translate"
        />
      } @else {
        <absence-list [absences]="reviewedPastAbsences" />
      }
    }
  }
</ng-template>
