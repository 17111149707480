import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { yearList } from '../../../../shared/date.helper';
import { AbsenteeOptionModel } from '../../../../reducers/orm/absentee-option/absentee-option.model';
import { getISOWeekYear } from 'date-fns';
import { Features } from '../../../../enums';

@Component({
  selector: 'employee-plusmin-overview',
  templateUrl: './employee-plusmin-overview.component.html',
  styleUrls: ['employee-plusmin-overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployeePlusminOverviewComponent {
  public years = [];
  private _year;
  public showWeeks = {};
  public features = Features;

  public readonly currentYear = getISOWeekYear(new Date());

  @Input()
  title: string;
  @Input()
  stats;
  @Input()
  absenteeOptions: AbsenteeOptionModel[];
  @Input()
  userId: string;
  @Input()
  showAbsence: boolean;

  @Input()
  loadingStats: boolean;
  @Input()
  loadingCorrections: boolean;

  @Input()
  set year(year) {
    this._year = year;
    this.showWeeks = {};
  }

  get year() {
    return this._year;
  }

  @Input()
  set start(year) {
    this.updateYearOptions(year);
  }

  @Output()
  yearChange = new EventEmitter<string>();
  @Output()
  removeCorrection = new EventEmitter<string>();

  constructor() {}

  private updateYearOptions(year) {
    if (!year) {
      return;
    }

    if (this.currentYear < year) {
      year = this.currentYear;
    }

    this.years = yearList(year, this.currentYear);
  }
}
