import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { TimesheetModel } from '../../../../../../reducers/orm/timesheet/timesheet.model';
import { TimesheetDayService } from '../../timesheet-day-service';

@Component({
  selector: 'timesheet-total-timesheet-edit',
  templateUrl: './timesheet-total-timesheet-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimesheetTotalTimesheetEditComponent {
  @Input()
  public timesheet: TimesheetModel;
  @Input()
  public time: number;
  @Input()
  public salary: number;
  @Input()
  public showSalary: boolean;

  public constructor(private timesheetDayService: TimesheetDayService) {}

  public changeStatus(status) {
    this.timesheetDayService.status$.next({ timesheet: this.timesheet, status });
  }
}
