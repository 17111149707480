import { of as observableOf, Observable } from 'rxjs';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, HostBinding, Input } from '@angular/core';
import { Cell } from '../../../../../shared/awesome-grid/interfaces';
import { ScheduleModel } from '../../../../../../reducers/orm/schedule/schedule.model';

@Component({
  selector: 'timesheet-total-schedule',
  templateUrl: './timesheet-total-schedule.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: Cell, useExisting: forwardRef(() => TimesheetTotalScheduleComponent) }],
})
export class TimesheetTotalScheduleComponent extends Cell {
  @Input()
  schedule: ScheduleModel;
  @Input()
  employeeId: string;
  @Input()
  dayIsClosed: boolean;

  @HostBinding('style.height.px')
  public height = 36;

  constructor(cd: ChangeDetectorRef) {
    super(cd);
  }
}
