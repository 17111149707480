import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LaddaDirective } from '@app/shared/ladda/ladda.directive';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TimesheetModelEnchanced } from '@reducers/orm/timesheet/timesheet.model';
import { TimesheetService } from '@reducers/orm/timesheet/timesheet.service';
import { ButtonComponent, IconComponent, SbDialogService, StatusIconComponent } from '@sb/ui';
import { filter, switchMap } from 'rxjs';

import { TimesheetTotalComponent } from '../timesheet-total/timesheet-total.component';

@Component({
  selector: 'timesheet-total-timesheet',
  templateUrl: './timesheet-total-timesheet.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ButtonComponent,
    IconComponent,
    TimesheetTotalComponent,
    StatusIconComponent,
    LaddaDirective,
    TranslateModule,
  ],
})
export class TimesheetTotalTimesheetComponent {
  @Input()
  public timesheet: TimesheetModelEnchanced;
  @Input()
  public time: number;
  @Input()
  public salary: number;
  @Input()
  public showSalary: boolean;

  public constructor(
    private timesheetService: TimesheetService,
    private readonly dialog: SbDialogService,
    private translate: TranslateService,
  ) {}

  public handleClockOut(): void {
    if (!this.timesheet.canClockOut && !this.timesheet.saving) {
      return;
    }

    void this.dialog
      .openConfirm({
        type: 'danger',
        title: this.translate.instant('Clock Out'),
        description: this.translate.instant(
          'Clocking out creates a timesheet with the recorded start time, end time, and breaks.',
        ),
        primary: {
          text: this.translate.instant('Clock out'),
        },
      })
      .closed.pipe(
        filter((result) => result?.confirmed),
        switchMap(() => this.timesheetService.clockOut(this.timesheet.id, this.timesheet.user_id)),
      )
      .subscribe();
  }
}
