@if (enhancedAbsence) {
  <ng-template #tooltip>
    <absence-tooltip [absences]="[enhancedAbsence]" [date]="date" [showName]="compactView && mode === 'team'" />
  </ng-template>

  <absence-item-content
    class="mt-schedule-item"
    [absence]="enhancedAbsence"
    [date]="date"
    [mode]="mode"
    [compactView]="compactView"
    [sbTooltip]="tooltip"
    [sbTooltipPlacement]="'top'"
    [sbTooltipMaxWidth]="'30rem'"
    [sbTooltipDelay]="400"
    [appAbsenceRequest]="{ absenceId: enhancedAbsence.id, originator: 'schedule' }"
  />
}
