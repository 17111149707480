import { CommonModule } from '@angular/common';
import { Component, DestroyRef, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { take } from 'rxjs';

import { CardComponent } from '../../../../../../../libs/ui/src';
import { getAuthenticatedUserId } from '../../../../../reducers/auth/auth.service';
import { EnhancedAbsenceModel } from '../../../../../reducers/orm/absence/absence.model';
import { AbsenceRowComponent } from './absence-row.component';

@Component({
  selector: 'absence-list',
  standalone: true,
  imports: [CommonModule, AbsenceRowComponent, CardComponent],
  templateUrl: './absence-list.component.html',
})
export class AbsenceListComponent implements OnInit {
  @Input()
  public absences: EnhancedAbsenceModel[] = [];

  public authUserId: string;

  public constructor(
    private store: Store,
    private destroyRef: DestroyRef,
  ) {}

  public ngOnInit() {
    this.setAuthUserId();
  }

  private setAuthUserId() {
    void this.store
      .select(getAuthenticatedUserId)
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe((authUserId) => {
        this.authUserId = authUserId;
      });
  }
}
