import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { AbsenceOptionUnit } from '@app/enums';
import { DecimalToDurationFormatPipe } from '@app/pipes/decimal-to-duration-format.pipe';
import { LocaleDatePipe } from '@app/pipes/locale-date.pipe';
import { AppState } from '@app/reducers';
import { AbsenteeOptionModel } from '@app/reducers/orm/absentee-option/absentee-option.model';
import { getAllAbsenteeOptions } from '@app/reducers/orm/absentee-option/absentee-option.service';
import { TimeDistributionAbsenceItem, TimeDistributionResponse } from '@app/reducers/orm/employee/employee.model';
import { ContentStateComponent } from '@app/shared/content-state/content-state.component';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AbsenceService } from '@reducers/orm/absence/absence.service';
import { CorrectionService } from '@reducers/orm/correction/correction.service';
import { EmployeeService } from '@reducers/orm/employee/employee.service';
import { HorizontalStackedBarChartComponent, StackedBarChartItem, StackedBarChartRestItem } from '@sb/ui';
import { trackByIdFn } from '@shared/trackby';
import { merge, Subscription } from 'rxjs';

@Component({
  selector: 'absence-type-distribution',
  templateUrl: './absence-type-distribution.component.html',
  standalone: true,
  imports: [CommonModule, TranslateModule, HorizontalStackedBarChartComponent, ContentStateComponent],
  providers: [LocaleDatePipe, DecimalToDurationFormatPipe],
})
export class AbsenceTypeDistributionComponent implements OnChanges, OnDestroy {
  @Input()
  public employeeId: string;

  @Input()
  public year: string;

  public trackById = trackByIdFn;
  public timeDistributionItems: StackedBarChartItem[];
  public hoursWorkedItem: StackedBarChartRestItem;
  public startDate: string;
  public endDate: string;
  public absenteeOptions: AbsenteeOptionModel[];
  public loading = false;

  private dataSubs = new Subscription();

  private currentYear = new Date().getFullYear().toString();

  public constructor(
    private store: Store<AppState>,
    private employeeService: EmployeeService,
    private datePipe: LocaleDatePipe,
    private decimalToDurationFormat: DecimalToDurationFormatPipe,
    private translate: TranslateService,
    private correctionService: CorrectionService,
    private absenceService: AbsenceService,
  ) {
    this.dataSubs.add(
      this.store.select(getAllAbsenteeOptions).subscribe((absenteeOptions) => {
        this.absenteeOptions = absenteeOptions;
      }),
    );

    this.dataSubs.add(
      merge(this.correctionService.listenToChanges(), this.absenceService.listenToChanges()).subscribe(() => {
        this.getData();
      }),
    );
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['employeeId'] || changes['year']) {
      this.getData();
    }

    if (changes['year']) {
      const dateFormat = { month: 'long', day: 'numeric' };

      this.startDate = this.datePipe.transform(`${this.year}-01-01`, dateFormat);
      this.endDate =
        this.year === this.currentYear
          ? this.translate.instant('Today')
          : this.datePipe.transform(`${this.year}-12-31`, dateFormat);
    }
  }

  public ngOnDestroy(): void {
    this.dataSubs.unsubscribe();
  }

  private getData() {
    this.loading = true;
    this.dataSubs.add(
      this.employeeService.getTimeDistribution(this.employeeId, this.year).subscribe({
        next: (data: TimeDistributionResponse) => {
          if (data) {
            this.timeDistributionItems = this.getTimeDistributionItems(data.absences);

            const workedHours = this.decimalToDurationFormat.transform(data.workedHours, AbsenceOptionUnit.HOURS);

            this.hoursWorkedItem = {
              label: this.translate.instant('Hours worked'),
              tooltipText: `${workedHours} (${data.workedHoursPercentage}%)`,
            };
          }
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        },
      }),
    );
  }

  private getTimeDistributionItems(absences: TimeDistributionAbsenceItem[]): StackedBarChartItem[] {
    return absences
      .filter((absence) => absence.percentage > 0)
      .map((absence) => {
        const absenceType = this.getAbsenceType(absence.absenceTypeId);
        const absenceValue = this.decimalToDurationFormat.transform(absence.value, absence.valueUnit);

        return {
          id: absence.absenceTypeId,
          label: absenceType.option,
          color: absenceType.color,
          percentage: absence.percentage,
          tooltipText: `${absenceValue} (${absence.percentage}%)`,
        };
      });
  }

  private getAbsenceType(id: string): AbsenteeOptionModel {
    return this.absenteeOptions.find((absence) => absence.id === id);
  }
}
