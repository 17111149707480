import { AsyncPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AbsenceRequestDirective } from '@app/+modals/employee/absence-request/absence-request.directive';
import {
  ButtonComponent,
  ButtonGroupComponent,
  DropdownModule,
  IconButtonComponent,
  IconComponent,
  InputModule,
  SbDialogModule,
  StatusButtonComponent,
  StatusIconComponent,
} from '@sb/ui';

import { KnowledgeBaseArticleLinkModule } from '../../pipes/knowledge-base-article-link.module';
import { SbCalendarModule } from '../../shared/sb-lib/calendar/sb-calendar.module';
import { SharedModule } from '../../shared/shared.module';
import { AbsenceCalendarComponent } from '../+employees/shared/employee-absence-overview/absence-calendar/absence-calendar.component';
import { AbsenceListSectionComponent } from '../+employees/shared/employee-absence-overview/absence-list/absence-list-section.component';
import { AbsenceTypeDistributionComponent } from '../+employees/shared/employee-absence-overview/absence-type-distribution/absence-type-distribution.component';
import { EmployeeAbsenceOverviewComponent } from '../+employees/shared/employee-absence-overview/employee-absence-overview.component';
import { TimeOffBalancesComponent } from '../+employees/shared/employee-absence-overview/time-off-balances/time-off-balances.component';
import { EmployeeHoursOverviewComponent } from '../+employees/shared/employee-hours-overview/employee-hours-overview.component';
import { EmployeePlusminOverviewComponent } from '../+employees/shared/employee-plusmin-overview/employee-plusmin-overview.component';
import { AbsenceItemComponent } from '../+schedule/employee/schedule-employee-n-period/schedule-items/absence/absence-item.component';
import { TimesheetDaySharedModule } from '../+timesheet/shared/day/timesheet-day-shared.module';
import { TimesheetSidebarComponent } from '../+timesheet/shared/timesheet/timesheet-sidebar/timesheet-sidebar.component';
import { PersonalScheduleComponent } from '../dashboard/my-schedule/personal-schedule.component';
import { AwesomeGridRowComponent } from './awesome-grid/awesome-grid-row.component';
import { AwesomeGridComponent } from './awesome-grid/awesome-grid.component';
import {
  AwesomeGridCenterDirective,
  AwesomeGridLeftDirective,
  AwesomeGridRightDirective,
} from './awesome-grid/awesome-grid.directives';
import {
  ContentHeaderComponent,
  ContentNavDirective,
  ContentNavItemComponent,
} from './content-header/content-header.component';
import { DepartmentsDropdownComponent } from './departments-dropdown/departments-dropdown.component';
import { ActionConfirmDirective } from './directives/action-confirm.directive';
import { FiltersToggleComponent } from './filters-toggle/filters-toggle.component';
import { SidebarHeaderComponent } from './sidebar/sidebar-header.component';
import { SplitButtonQuotaDirective } from './split-button-quota.directive';

const imports = [
  SharedModule,
  RouterModule,
  TimesheetDaySharedModule,
  ContentHeaderComponent,
  ContentNavItemComponent,
  ContentNavDirective,
  IconComponent,
  ActionConfirmDirective,
  StatusButtonComponent,
  StatusIconComponent,
  SplitButtonQuotaDirective,
  InputModule,
  IconButtonComponent,
  AbsenceTypeDistributionComponent,
  AbsenceListSectionComponent,
  ButtonGroupComponent,
  AbsenceCalendarComponent,
  ButtonComponent,
  TimeOffBalancesComponent,
  SbDialogModule,
  SidebarHeaderComponent,
  DepartmentsDropdownComponent,
  FiltersToggleComponent,
  DropdownModule,
  AsyncPipe,
];

const declarations = [
  EmployeePlusminOverviewComponent,
  EmployeeAbsenceOverviewComponent,
  EmployeeHoursOverviewComponent,
  TimesheetSidebarComponent,
  PersonalScheduleComponent,
  AwesomeGridComponent,
  AwesomeGridRowComponent,
  AwesomeGridLeftDirective,
  AwesomeGridCenterDirective,
  AwesomeGridRightDirective,
];

const standalone = [AbsenceRequestDirective, AbsenceItemComponent];

@NgModule({
  imports: [...imports, ...standalone, KnowledgeBaseArticleLinkModule, SbCalendarModule],
  declarations: declarations,
  exports: [...imports, ...declarations, ...standalone],
})
export class SharedAuthModule {}
