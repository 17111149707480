<h4 class="m-0 text-lg font-bold text-word-mark-800">
  {{ 'Balances' | translate }}
</h4>
<p class="flex items-center gap-1 text-sm font-semibold text-grey-500">
  {{ startDate | localeDate: { day: 'numeric', month: 'long' } }} -
  {{ endDate | localeDate: { month: 'long', day: 'numeric' } }}
  @if (endContractDate) {
    <sb-icon
      [name]="'question-circle'"
      [sbTooltip]="
        'Contract ends on [endContractDate]'
          | translate
          | translationParams
            : { endContractDate: endContractDate | localeDate: { year: 'numeric', month: 'long', day: 'numeric' } }
      "
      [sbTooltipPlacement]="'top'"
      [sbTooltipMaxWidth]="'18rem'"
    ></sb-icon>
  }
</p>
<content-state [isLoading]="loading" [isEmpty]="!balances?.length">
  <div class="flex flex-col gap-2">
    @for (balance of balances; track $index) {
      <time-off-balance
        [balance]="balance"
        [year]="year"
        [employee]="employee"
        [isActive]="balanceStatusMap[balance.timeOffBalanceId]"
        (toggled)="onBalanceToggle(balance)"
      >
      </time-off-balance>
    }
  </div>
</content-state>
