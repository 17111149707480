import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, of as observableOf, Subscription } from 'rxjs';
import { debounceTime, filter, map, switchMap, tap } from 'rxjs/operators';

import { FormChange, formChange } from '../../../../../../forms/form.helper';
import { ValidationService } from '../../../../../../forms/validation.service';
import { AppState } from '../../../../../../reducers';
import { CustomFieldsModel } from '../../../../../../reducers/orm/custom-fields/custom-fields.model';
import { DepartmentModel } from '../../../../../../reducers/orm/department/department.model';
import { LocationModel } from '../../../../../../reducers/orm/location/location.model';
import { RateCardModel } from '../../../../../../reducers/orm/rate-card/rate-card.model';
import { ScheduleModel } from '../../../../../../reducers/orm/schedule/schedule.model';
import { ShiftModel } from '../../../../../../reducers/orm/shift/shift.model';
import { filterShiftOptionsPerDepartment } from '../../../../../../reducers/orm/shift/shift.service';
import { TeamModel } from '../../../../../../reducers/orm/team/team.model';
import { filterTeamOptionsPerDepartment } from '../../../../../../reducers/orm/team/team.service';
import { TimesheetModel } from '../../../../../../reducers/orm/timesheet/timesheet.model';
import { getTimesheet, TimesheetService } from '../../../../../../reducers/orm/timesheet/timesheet.service';
import {
  TimesheetColumns,
  TimesheetCustomFieldsColumns,
} from '../../../../../../reducers/page-filters/page-filters.model';
import { format, parseDate } from '../../../../../../shared/date.helper';
import { lazySelect } from '../../../../../../shared/lazy-select.observable';
import { TimesheetScheduleDeviations } from '../../../interfaces';

@Component({
  selector: 'timesheet-row-clocked-in',
  templateUrl: './timesheet-row-clocked-in.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimesheetRowClockedInComponent implements OnInit, OnDestroy {
  @Input()
  public timesheet: TimesheetModel;
  @Input()
  public schedule: ScheduleModel;
  @Input()
  public customFields: CustomFieldsModel[] = [];

  @Input()
  public locations: { [id: string]: LocationModel };
  @Input()
  public departments: { [id: string]: DepartmentModel };
  @Input()
  public departmentOptions: [
    {
      location: LocationModel;
      departments: DepartmentModel[];
    },
  ];

  private _teams: TeamModel[];
  @Input()
  public set teams(teams) {
    this._teams = teams;
    this.setTeamOptions();
  }

  private _shifts: ShiftModel[];
  @Input()
  public set shifts(shifts) {
    this._shifts = shifts;
    this.setShiftOptions();
  }

  public rateCardOptions;
  public selectedRateCard: RateCardModel;

  @Input()
  public set rateCards(rateCards: RateCardModel[]) {
    this.selectedRateCard = rateCards.find((rateCard) => rateCard.id == this.timesheet.rate_card_id);
    this.rateCardOptions = rateCards
      .filter((rateCard) => {
        if (rateCard.id === this.timesheet.rate_card_id) return true;

        return !rateCard.deleted;
      })
      .map((rateCard) => {
        let rateCardName = rateCard.name;

        if (rateCard.deleted) {
          const deletedAt = parseDate(rateCard.deleted_date);
          rateCardName += ' - ' + format(deletedAt, 'P');
        }

        return { value: rateCard.id, label: rateCardName };
      });
  }

  @Input()
  public absenceWarning: boolean;
  @Input()
  public doubleRegistration: boolean;
  @Input()
  public deviationsFromSchedule: TimesheetScheduleDeviations;

  @Input()
  public cols: TimesheetColumns;
  @Input()
  public customFieldCols: TimesheetCustomFieldsColumns;

  @Input()
  public widths;

  public form = new UntypedFormGroup({
    id: new UntypedFormControl('', Validators.required),
    date: new UntypedFormControl('', { validators: [Validators.required], updateOn: 'blur' }),
    starttime: new UntypedFormControl('', {
      validators: [Validators.required, ValidationService.timeValidator],
      updateOn: 'blur',
    }),
    kilometers: new UntypedFormControl('', {
      validators: [Validators.required, ValidationService.decimalValidator],
      updateOn: 'blur',
    }),
    meals: new UntypedFormControl('', {
      validators: [Validators.required, ValidationService.numberValidator],
      updateOn: 'blur',
    }),
    note: new UntypedFormControl('', { updateOn: 'blur' }),
    user_id: new UntypedFormControl('', Validators.required),
    department_id: new UntypedFormControl('', Validators.required),
    shift_id: new UntypedFormControl('', Validators.required),
    team_id: new UntypedFormControl('', Validators.required),
    rate_card_id: new UntypedFormControl('', Validators.required),
  });

  public selectedDepartment: DepartmentModel;
  public selectedLocation: LocationModel;

  public teamOptions;
  public shiftOptions;

  private formChanges: Subscription;

  private internalChange = false;
  private externalChanges: Subscription;

  public constructor(
    private fb: UntypedFormBuilder,
    private cd: ChangeDetectorRef,
    private timesheetService: TimesheetService,
    private store: Store<AppState>,
  ) {}

  public ngOnInit(): void {
    this.setDepartment(this.timesheet.department_id);
    this.initForm();

    this.externalChanges = this.store
      .pipe(
        lazySelect(getTimesheet(this.timesheet.id)),
        filter(() => !this.internalChange),
      )
      .subscribe((data) => {
        this.form.patchValue(data, { emitEvent: false });
      });
  }

  public ngOnDestroy(): void {
    this.formChanges.unsubscribe();
    this.externalChanges.unsubscribe();
  }

  public onNoteChange(value: string): void {
    this.form.controls['note'].setValue(value);
  }

  private initForm() {
    const formResetOptions = {
      onlySelf: true,
      emitEvent: false,
    };

    this.form.reset(this.timesheet, formResetOptions);

    this.formChanges = formChange(this.form)
      .pipe(
        switchMap((formChange) => this.checkChange(formChange)),
        filter((change) => change !== false),
        debounceTime(200),
        tap(() => (this.internalChange = true)),
        switchMap((formChange: FormChange) => this.saveTimesheet(formChange)),
      )
      .subscribe(
        (data) => {
          this.cd.markForCheck();
          this.internalChange = false;
        },
        () => {
          this.internalChange = false;
        },
      );
  }

  private checkChange(formChange: FormChange): Observable<FormChange | boolean> {
    if (formChange.control === 'department_id') {
      return this.changeDepartment(formChange.controlValue);
    }

    if (formChange.control === 'shift_id') {
      return this.checkRateCard(formChange);
    }

    if (!formChange.formValid) {
      return observableOf(false);
    }

    return observableOf(formChange);
  }

  private checkRateCard(formChange: FormChange): Observable<FormChange | boolean> {
    //check starttime and endtime values
    if (!this.form.get('shift_id').valid) {
      return observableOf(false);
    }

    return this.timesheetService.checkRateCard(formChange.formValue).pipe(
      map((response) => {
        if (response.rate_card_id === false) {
          return formChange;
        }

        const control = this.form.get('rate_card_id');
        if (response.rate_card_id == control.value) {
          return formChange;
        }

        control.setValue(response.rate_card_id);
        return false;
      }),
    );
  }

  private saveTimesheet(formChange: FormChange) {
    if (!formChange.formValid) {
      return observableOf(false);
    }

    return this.timesheetService.save(formChange.formValue);
  }

  private changeDepartment(departmentId) {
    if (this.selectedDepartment.id === departmentId) {
      return observableOf(false);
    }

    this.form.get('team_id').setValue(null);
    this.form.get('shift_id').setValue(null);

    this.setDepartment(departmentId);
    this.cd.markForCheck();

    return observableOf(false);
  }

  private setDepartment(departmentId) {
    this.form.get('department_id').patchValue(departmentId);

    this.selectedDepartment = this.departments[departmentId];
    this.selectedLocation = this.locations[this.selectedDepartment.location_id];

    this.setTeamOptions();
    this.setShiftOptions();
  }

  public changeStatus(status) {
    this.form.get('status').patchValue(status);
  }

  private setTeamOptions() {
    if (!this.form.get('department_id').value || !this._teams) {
      return;
    }

    const teamFilter = filterTeamOptionsPerDepartment(this.form.get('department_id').value, this.timesheet.team_id);

    this.teamOptions = teamFilter(this._teams).map((team) => ({ value: team.id, label: team.name }));

    const teamControl = this.form.get('team_id');

    //set team if only 1 option
    if (this.teamOptions.length === 1) {
      const teamToSelect = this.teamOptions[0].value;

      if (teamControl.value !== teamToSelect) {
        teamControl.setValue(teamToSelect);
      }
    }

    const ids = this.teamOptions.map((option) => option.value);

    if (this.teamOptions.length > 1 && ids.indexOf(teamControl.value) === -1) {
      teamControl.setValue('');
    }
  }

  private setShiftOptions() {
    if (!this.form.get('department_id').value || !this._shifts) {
      return;
    }

    const shiftFilter = filterShiftOptionsPerDepartment(this.form.get('department_id').value, this.timesheet.shift_id);

    this.shiftOptions = shiftFilter(this._shifts).map((shift) => ({ value: shift.id, label: shift.long_name }));

    const shiftControl = this.form.get('shift_id');

    //set shift if only 1 option
    if (this.shiftOptions.length === 1) {
      const shiftToSelect = this.shiftOptions[0].value;

      if (shiftControl.value !== shiftToSelect) {
        shiftControl.setValue(shiftToSelect);
      }
    }

    const ids = this.shiftOptions.map((option) => option.value);

    if (this.shiftOptions.length > 1 && ids.indexOf(shiftControl.value) === -1) {
      shiftControl.setValue('');
    }
  }
}
