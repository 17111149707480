import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, HostBinding, Input } from '@angular/core';

import { EnhancedAbsenceModel } from '../../../../../../reducers/orm/absence/absence.model';
import { PlanType } from '../../../../../+reports/shared/subscriptions/subscription.model';
import { Cell } from '../../../../../shared/awesome-grid/interfaces';

@Component({
  selector: 'timesheet-total-absence',
  templateUrl: './timesheet-total-absence.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: Cell, useExisting: forwardRef(() => TimesheetTotalAbsenceComponent) }],
})
export class TimesheetTotalAbsenceComponent extends Cell {
  @Input()
  public absence: EnhancedAbsenceModel;
  @Input()
  public showTotals = true;
  @Input()
  public showSalary: boolean;

  @HostBinding('style.height.px')
  public height = 36;

  public planType = PlanType;

  public constructor(cd: ChangeDetectorRef) {
    super(cd);
  }
}
