<div class="flex flex-col gap-3">
  <sb-calendar
    [months]="2"
    [date]="date"
    [minDate]="minDate"
    [maxDate]="maxDate"
    [locale]="locale"
    (dateChange)="(onDateChange)"
  >
    <ng-template #dayTemplate let-day="day">
      <absence-calendar-day [absences]="getDayAbsences(day)" [day]="day"></absence-calendar-day>
    </ng-template>
  </sb-calendar>

  <div class="flex items-start justify-between gap-6">
    <sb-legend [items]="legendItems"></sb-legend>
    <div class="flex shrink-0 items-center gap-4 divide-x divide-y-0 divide-solid divide-grey-200">
      <sb-legend
        [items]="[
          {
            label: 'Approved' | translate,
            color: '#475467'
          },
          {
            label: 'Pending' | translate,
            color: '#475467',
            outline: true
          }
        ]"
      ></sb-legend>
      <a
        class="flex items-center gap-1 pl-4 text-xs text-primary-500"
        [routerLink]="['', { outlets: { modal: ['calendar-sync'] } }]"
      >
        <sb-icon [name]="'calendar-month'"></sb-icon>
        {{ 'Add to calendar' | translate }}
      </a>
    </div>
  </div>
</div>
