import {
  AfterContentChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnChanges,
  OnInit,
} from '@angular/core';
import { fromEvent, merge, Subscription } from 'rxjs';
import { debounceTime, filter, map, mergeMap, pairwise, takeUntil, tap } from 'rxjs/operators';

import { HorizontalScrollService } from '../../../shared/horizontal-scroll/horizontal-scroll.service';
import { ScheduleResizeService } from '../../+schedule/shared/schedule-resize/schedule-resize.service';
import { AwesomeGridService } from './awesome-grid.service';
import { GridWidth } from './interfaces';

/*
   What is the <awesome-grid> you ask? An amazing piece of technology that
   powers the most important features of the ShiftTime Application.

   It has the following features:

   1. The <awesome-grid>'s first and last column are fixed. This means
      that they are always visible. The columns in the center will
      take the remaining space available.

   2. When the center grid does not fit on the page, the center becomes
      horizontally scrollable.

   3. A row has the height of the highest Cell in the row.

   4. A row is not rendered unless it is visible in the viewport.

   Here's some ASCII art:

   ======================================================================
   |   First   |                   Center                   |   Last    |
   ======================================================================
   |           |                                            |           |
   |           |                                            |           |
   |           |                                            |           |
   ======================================================================

   <awesome-grid> can be used like so:

   ```html
   <awesome-grid
     [width]="{ left: 200, center: 1024 }"
     [showRight]="true"
   >
     <awesome-grid-row>
      <some-cell-component awesome-grid-left></some-cell-component>
      <some-cell-component awesome-grid-center></some-cell-component>
      <some-cell-component awesome-grid-right></some-cell-component>
    </awesome-grid-row>

    <awesome-grid-row>
      <some-cell-component awesome-grid-left></some-cell-component>
      <some-cell-component awesome-grid-center></some-cell-component>
      <some-cell-component awesome-grid-right></some-cell-component>
    </awesome-grid-row>

    <awesome-grid-row>
      <some-cell-component awesome-grid-left></some-cell-component>
      <some-cell-component awesome-grid-center></some-cell-component>
      <some-cell-component awesome-grid-right></some-cell-component>
    </awesome-grid-row>
   </awesome-grid>
   ```

   An <awesome-grid-row> contains a number of Cell's. A Cell has the following
   protocol:

   ```js
   export abstract class Cell {
     abstract requiredHeight: Observable<number>;

     abstract setHeight(height: number): void;

     abstract visible: boolean;
     abstract setVisible(visible: boolean): void;
   }
   ```

   A Cell must know how to answer the following question: "what is the
   minimum height required to render the component?" For each Cell in the
   row the answers are collected, the highest answer will be the height
   off the Row, this height is then communicated back to the Cell via
   `setHeight` the Cell must then respect its new height.

   For example: we might have a Row which represents an Employee's schedule.
   The first column shows the Employee's name, the second column a schedule
   and the third column shows the total. The info and total columns require
   a minimumHeight of 50 pixels, the schedule is very large and it requires
   200 pixels to render, the total and info must now become 200 pixels large.

   // TODO explain the mechanism behind the visibilty in great detail as it is rather complex.

   Now lets talk about performance.

   Rendering massive amounts of <awesome-grid-row>'s is not a good idea performance
   wise. When rendering huge numbers of Cell's which contain listeners to the DOM such
   as mouse up / down, scroll / keyboard behavior, the performance plummets.

   So the <awesome-grid> needs a way to optimize performance. What we need to
   do is significantly reduce the amount of DOM needed, and reduce the amount
   of listeners where we can. We can do this by only rendering the <awesome-grid-row>'s
   which are currently in the viewport.

   At this point we hit a bit of a chicken and egg problem. To know if a Row is
   visible we need the Row have its correct height. The height of a Row is
   determined by the height of the biggest Cell. So in order to get the height
   of the Row all Cells need to be rendered. Which is not what we want! We want
   to render LESS not MORE!

   The solution for this problem is having a `visible` state for each Cell. When
   visible is `true`, the Cell should render normally. When `visible` is `false`
   the Cell should render as little DOM as possible, but render at the height it
   is instructed. This way the Cell has a small footprint but the correct height.

   This way rendering a Row does not have to cost that much, so all Row's can
   be rendered initially, and we hide them later. This is why all Cell's should
   be invisible, and all Row's visible by default.

   In steps from beginning to end this is what happens:

    Step 1: Render all Row's and their Cell's. All Row's are visible, and
            all Cell's are invisible at this point.

    Step 2: Cell's report the height to the Row one by one. When all Cell's height
            are in, calculate the height of the Row.

    Step 3: Now check if the Row is in the viewport. Hide the row / cell based
            on the outcome.

   The performance optimizations do come at a price, whilst on the one hand you
   can now scroll through pages with an enormous amount of data, you will, on slower
   browsers such as FireFox and Safari, see white spaces when scrolling really fast.

   The <awesome-grid> will also check for inconsistencies in the rows provided.
   It will check if all rows have the same number of columns, this prevents
   developer errors from occurring.
 */
@Component({
  selector: 'awesome-grid',
  template: `
    <div class="awesome-grid">
      <ng-content></ng-content>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [AwesomeGridService],
})
export class AwesomeGridComponent implements OnChanges, OnInit, AfterContentChecked {
  @Input()
  public width: GridWidth;
  @Input()
  public showRight = true;

  private isScrollDragging: boolean;
  private dataSubs = new Subscription();

  public constructor(
    private awesomeGridService: AwesomeGridService,
    private cd: ChangeDetectorRef,
    private element: ElementRef,
    private zone: NgZone,
    private horizontalScrollService: HorizontalScrollService,
    private scheduleResizeService: ScheduleResizeService,
  ) {
    this.cd.detach();
  }

  public ngOnInit(): void {
    this.zone.runOutsideAngular(() => {
      this.setupScrollDrag();
    });
  }

  public ngAfterContentChecked() {
    this.cd.detectChanges();
  }

  private getTouchPosition(event: TouchEvent) {
    return {
      x: event.changedTouches[0].clientX,
      y: event.changedTouches[0].clientY,
    };
  }

  private getMousePosition(event: MouseEvent) {
    return {
      x: event.clientX,
      y: event.clientY,
    };
  }

  private setupScrollDrag() {
    const click$ = fromEvent(document, 'click', { capture: true });

    const scrollDragStart$ = merge(
      fromEvent(this.element.nativeElement, 'mousedown'),
      fromEvent(this.element.nativeElement, 'touchstart'),
    ).pipe(
      tap(() => {
        this.isScrollDragging = false;
      }),
    );

    const scrollDragMove$ = merge(
      fromEvent(document, 'mousemove').pipe(map((event: MouseEvent) => this.getMousePosition(event))),
      fromEvent(document, 'touchmove').pipe(map((event: TouchEvent) => this.getTouchPosition(event))),
    );

    const scrollDragEnd$ = merge(
      fromEvent(document, 'mouseup'),
      fromEvent(document, 'touchend'),
      fromEvent(document, 'drag'),
      this.scheduleResizeService.isResizing$.pipe(filter((isResizing) => isResizing)),
    );

    this.dataSubs.add(
      click$.subscribe((event: MouseEvent) => {
        if (!this.isScrollDragging) {
          return;
        }
        this.isScrollDragging = false;
        event.stopPropagation();
        event.stopImmediatePropagation();
        event.preventDefault();
      }),
    );

    const scrollDrag$ = scrollDragStart$.pipe(
      mergeMap(() =>
        scrollDragMove$.pipe(
          debounceTime(5),
          pairwise(),
          map(([prev, current]: [MouseEvent, MouseEvent]) => prev.x - current.x),
          filter((x) => x !== 0),
          tap(() => {
            this.isScrollDragging = true;
          }),
          takeUntil(scrollDragEnd$),
        ),
      ),
    );

    this.dataSubs.add(
      scrollDrag$.subscribe((delta: number) => {
        this.deltaScroll(delta);
      }),
    );
  }

  public ngOnChanges() {
    this.awesomeGridService.setData(this.width, this.showRight);
    this.cd.detectChanges();
  }

  private deltaScroll(delta: number) {
    if (delta === 0) {
      return;
    }
    if (delta > 0) {
      this.horizontalScrollService.scrollRight(delta);
    } else if (delta < 0) {
      this.horizontalScrollService.scrollLeft(delta * -1);
    }
  }
}
