import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ScheduleModel } from '../../../../../../reducers/orm/schedule/schedule.model';
import { DepartmentModel } from '../../../../../../reducers/orm/department/department.model';
import { LocationModel } from '../../../../../../reducers/orm/location/location.model';
import { TimesheetColumns } from '../../../../../../reducers/page-filters/page-filters.model';

@Component({
  selector: 'timesheet-row-schedule',
  templateUrl: './timesheet-row-schedule.component.html',
  host: {
    class: 'timesheet__schedule-info',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimesheetRowScheduleComponent {
  @Input()
  schedule: ScheduleModel;

  @Input()
  locations: { [id: string]: LocationModel };
  @Input()
  department: DepartmentModel;

  @Input()
  cols: TimesheetColumns;
  @Input()
  widths;
}
