<ng-container *ngIf="!dayIsClosed && (team?.type === 'default' || !team)">
  <a
    *permission="['Create timesheets', 'Create own timesheet']; user: employeeId; department: team?.department_id"
    [routerLink]="['', { outlets: { modal: ['timesheet', modalData] } }]"
    class="btn btn-secondary button-icon button-add inline-flex items-center justify-center"
    [attr.aria-label]="'Add' | translate"
  >
    <icon class="si" svgIcon="sum"></icon>
  </a>
</ng-container>
