import { Injectable } from '@angular/core';
import { GridWidth } from './interfaces';
import { BehaviorSubject, Subject } from 'rxjs';
import { ElementInViewportService } from '../../../shared/element-in-viewport.service';

@Injectable()
export class AwesomeGridService {
  public widths: GridWidth;
  public showRight: boolean;

  constructor(private elementInViewportService: ElementInViewportService) {}
  public setData(widths: GridWidth, showRight: boolean) {
    this.widths = widths;
    this.showRight = showRight;

    this.elementInViewportService.triggerOutsideScopeChanges();
  }
}
