import { createSelector } from 'reselect';
import { ScheduleFilterState } from '@reducers/page-filters/page-filters.model';
import { getScheduleFilters } from '@reducers/page-filters/page-filters.service';

export const getScheduleCompactOption = createSelector(
  getScheduleFilters,
  (scheduleFilters: ScheduleFilterState) => scheduleFilters?.showOptions?.compact
);

export const scheduleCompactViewEnabled = createSelector(
  getScheduleFilters,
  (scheduleFilters: ScheduleFilterState) =>
    scheduleFilters?.period?.periodType === 'month' && scheduleFilters?.showOptions?.compact
);
