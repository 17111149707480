import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent, buttonThemePalette, IconComponent } from '@sb/ui';

@Component({
  selector: 'filters-toggle',
  templateUrl: './filters-toggle.component.html',
  standalone: true,
  imports: [CommonModule, ButtonComponent, IconComponent, TranslateModule],
})
export class FiltersToggleComponent implements OnChanges {
  public color: buttonThemePalette = 'secondary';

  @Input({ required: true })
  public isActive: boolean;

  @Output()
  public toggleClick = new EventEmitter<void>();

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['isActive']) {
      // TODO: Enable this line when adding the badge to the button
      // And make sure outlinePrimary is the desired color for the active state
      // this.color = this.isActive ? 'outlinePrimary' : 'secondary';
    }
  }
}
