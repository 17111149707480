import { of as observableOf, Observable } from 'rxjs';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, HostBinding, Input } from '@angular/core';
import { Cell } from '../../../../../shared/awesome-grid/interfaces';
import { defaultTotal } from '../../../../../../shared/total.helper';

@Component({
  selector: 'timesheet-day-info',
  templateUrl: './timesheet-day-info.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: Cell, useExisting: forwardRef(() => TimesheetDayInfoComponent) }],
})
export class TimesheetDayInfoComponent extends Cell {
  @Input()
  date: string;
  @Input()
  total = defaultTotal;
  @Input()
  showSalary: boolean;
  @Input()
  scheduleDiff;
  @Input()
  show: boolean = true;
  @Input()
  last: boolean = true;

  @HostBinding('style.height.px')
  public height = 36;

  constructor(cd: ChangeDetectorRef) {
    super(cd);
  }
}
