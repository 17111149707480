<div
  class="group relative flex items-center gap-2 rounded-base py-1.5"
  [ngClass]="{
    'bg-white hover:pl-2 hover:pr-7 hover:bg-grey-100 transition-all duration-300 ease-in-out':
      item.correction && canDelete
  }"
  [sbTooltip]="tooltipTemplate"
  [sbTooltipDisabled]="!item.correction"
  [sbTooltipPlacement]="'left'"
  [sbTooltipMaxWidth]="'18rem'"
>
  <span class="flex-1">{{ item.label }}</span>
  <span>
    {{ item.sign }} {{ item.value }}
    @if (item.moreDetails) {
      ({{ item.moreDetails }})
    }
  </span>
  @if (item.correction && canDelete) {
    <action-confirm
      class="absolute -right-1 cursor-pointer opacity-0 transition-all duration-300 ease-in-out group-hover:right-2 group-hover:opacity-100"
      (onConfirm)="deleteCorrection(item.correction.id)"
      [icon]="'delete'"
    ></action-confirm>
  }
</div>
<ng-template #tooltipTemplate>
  <div class="flex flex-col gap-4 text-sm font-semibold text-white">
    <div class="flex gap-2">
      <sb-badge-icon
        class="bg-grey-100 text-grey-500"
        [size]="'xl'"
        [name]="iconMap[item.correctionType]"
      ></sb-badge-icon>
      <div class="flex flex-col">
        <span>{{ item.correctionType }}</span>
        <span class="text-xs font-medium">{{ 'by' | translate }} {{ item.correction.createdBy }}</span>
      </div>
    </div>
    <div class="flex justify-between gap-10">
      <span>{{ 'Correction date' | translate }}</span>
      <span>{{ item.correction.date | localeDate }}</span>
    </div>
    @if (item.correction.pay) {
      <div class="flex justify-between gap-10">
        <span>{{ 'Payout date' | translate }}</span>
        <span>{{ item.correction.payoutDate | localeDate }}</span>
      </div>
    }
    <div class="flex justify-between gap-10">
      <span>{{ 'Created on' | translate }}</span>
      <span>{{ item.correction.created | localeDate: intlFormatType.DATETIME }}</span>
    </div>
    @if (item.correction.note) {
      <hr class="m-0 border-solid border-white" />
      <div class="flex gap-2">
        <sb-icon name="notepad"></sb-icon>
        <span>{{ item.correction.note }}</span>
      </div>
    }
  </div>
</ng-template>
