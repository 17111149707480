import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';

import { TeamModel } from '../../../../../../reducers/orm/team/team.model';

@Component({
  selector: 'timesheet-total-timesheet-add',
  templateUrl: './timesheet-total-timesheet-add.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimesheetTotalTimesheetAddComponent implements OnChanges {
  @Input()
  employeeId: string;
  @Input()
  team: TeamModel;
  @Input()
  date: string;
  @Input()
  dayIsClosed: boolean;

  public modalData = {};

  ngOnChanges() {
    this.modalData = { employee: this.employeeId };
    if (this.team) {
      this.modalData = { ...this.modalData, team: this.team.id, department: this.team.department_id };
    }
    if (this.date) {
      this.modalData = { ...this.modalData, date: this.date };
    }
  }
}
