export function debounce(delay: number = 300): MethodDecorator {
  return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
    let timeoutKey;
    if (!target[timeoutKey]) {
      target[timeoutKey] = Symbol();
    }

    const original = descriptor.value;

    descriptor.value = function (...args) {
      clearTimeout(this[target[timeoutKey]]);
      this[target[timeoutKey]] = setTimeout(() => original.apply(this, args), delay);
    };

    return descriptor;
  };
}
