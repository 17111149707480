<div class="flex flex-col rounded-base bg-grey-100">
  <div
    class="flex cursor-pointer gap-2.5 px-2.5 py-2 text-sm font-semibold text-word-mark-800"
    role="button"
    (click)="isActive = !isActive"
  >
    <sb-icon
      name="chevron-right"
      size="xxs"
      class="self-center text-grey-500 transition-transform ease-in-out"
      [ngClass]="{ 'rotate-90': isActive }"
    >
    </sb-icon>
    <span class="flex-1">{{ partial.label }} </span>
    <span>{{ partial.value }}</span>
  </div>
  <div
    class="grid transition-collapse duration-300 ease-in-out"
    [ngClass]="{ 'grid-rows-collapse': !isActive, 'pb-2 grid-rows-collapse-expanded': isActive }"
  >
    <div class="flex flex-col overflow-hidden px-1.5">
      <div
        class="flex flex-col rounded-base bg-white p-2 text-sm font-medium text-grey-500 transition-opacity duration-300 ease-in-out"
        [ngClass]="{ 'opacity-30': !isActive, 'opacity-100': isActive }"
      >
        @if (partial.items.length) {
          @for (item of partial.items; track $index) {
            <time-off-partial-balance-item [item]="item" [employee]="employee"> </time-off-partial-balance-item>
          }
        } @else {
          <div class="flex items-center justify-center p-3">
            <span class="text-grey-400">{{ 'No corrections' | translate }}</span>
          </div>
        }
        <div class="my-2">
          <hr class="m-0 border-solid border-grey-200" />
        </div>
        <div class="flex justify-between font-bold text-word-mark-800">
          <span>{{ 'Total' | translate }}</span>
          <span>{{ partial.value }}</span>
        </div>
      </div>

      @if (partial.itemsAreCorrections) {
        <button
          *permission="['Adjust time off balances']; user: employee?.id; department: employee?.departments"
          [routerLink]="[
            '',
            { outlets: { modal: ['time-off-correction', 'time_off_balance', employee?.id, partial.balanceId] } }
          ]"
          sb-button
          color="primary"
          class="mt-2"
        >
          {{ 'Make correction' | translate }}
        </button>
      }
    </div>
  </div>
</div>
