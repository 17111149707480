<!-- remove outline from button in timesheets -->
<div class="timesheet__actions flex items-center gap-1 focus:children:outline-0">
  <a
    sb-icon-button
    [color]="'transparent'"
    [routerLink]="['', { outlets: { modal: ['timesheet', timesheet.id, 'edit'] } }]"
  >
    <sb-icon [name]="'pen'"></sb-icon>
    <span class="sr-only">{{ 'Edit' | translate }}</span>
  </a>

  @if (timesheet.active_clock && timesheet.canClockOut) {
    <button sb-button [sbLadda]="timesheet.saving" [color]="'red'" (click)="handleClockOut()">
      {{ 'Clock out' | translate }}
    </button>
  }
</div>

<timesheet-total [time]="time" [salary]="salary" [showSalary]="showSalary"></timesheet-total>
