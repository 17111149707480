import { NgClass, NgIf } from '@angular/common';
import { Component, Input, Directive, EventEmitter, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-content-header',
  template: ` <ng-content select="[buttonsNav]"></ng-content>
    <ng-content select="[contentNav]"></ng-content>
    <ng-content select="[actionsNav]"></ng-content>
    <ng-content></ng-content>`,
  standalone: true,
})
export class ContentHeaderComponent {
  @Input()
  public title: string;
}

@Directive({
  selector: '[contentNav]',
  host: {
    class: 'secondary-menu',
  },
  standalone: true,
})
export class ContentNavDirective {}

@Component({
  selector: '[contentNavItem]',
  host: {
    class: 'secondary-menu__item',
  },
  template: `
    <a
      class="secondary-menu__link"
      [ngClass]="{ 'menu-locked': locked }"
      [routerLink]="locked ? null : link"
      [routerLinkActive]="locked ? '' : 'secondary-menu__link--active'"
      [routerLinkActiveOptions]="{ exact: exact }"
      (click)="clicked()"
    >
      <span class="secondary-menu__label">
        <icon *ngIf="locked" class="header__navbar-link-icon" [svgIcon]="locked && 'lock'"></icon> {{ label }}</span
      >
    </a>
  `,
  standalone: true,
  imports: [NgClass, NgIf, RouterModule, MatIconModule],
})
export class ContentNavItemComponent {
  @Input()
  public link: any[] | string;
  @Input()
  public icon: string;
  @Input()
  public label: string;
  @Input()
  public exact: true;
  @Input()
  public locked: boolean;
  @Output()
  public lockClick = new EventEmitter();

  public constructor() {}

  public clicked() {
    if (!this.locked) {
      return;
    }

    this.lockClick.emit();
  }
}

export const CONTENT_HEADER_DIRECTIVES: any[] = [ContentHeaderComponent, ContentNavItemComponent, ContentNavDirective];
