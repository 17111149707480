import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { CustomFieldsModel } from '../../../../../../reducers/orm/custom-fields/custom-fields.model';
import { DepartmentModel } from '../../../../../../reducers/orm/department/department.model';
import { LocationModel } from '../../../../../../reducers/orm/location/location.model';
import { RateCardModel } from '../../../../../../reducers/orm/rate-card/rate-card.model';
import { TimesheetModel } from '../../../../../../reducers/orm/timesheet/timesheet.model';
import {
  TimesheetColumns,
  TimesheetCustomFieldsColumns,
} from '../../../../../../reducers/page-filters/page-filters.model';
import { format, parseDate } from '../../../../../../shared/date.helper';
import { TimesheetScheduleDeviations } from '../../../interfaces';

@Component({
  selector: 'timesheet-row-timesheet',
  templateUrl: './timesheet-row-timesheet.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimesheetRowTimesheetComponent implements OnChanges {
  @Input()
  public timesheet: TimesheetModel;
  @Input()
  public customFields: CustomFieldsModel[] = [];

  @Input()
  public locations: { [id: string]: LocationModel };
  @Input()
  public department: DepartmentModel;

  public selectedRateCard: RateCardModel;

  @Input()
  public set rateCards(rateCards: RateCardModel[]) {
    this.selectedRateCard = rateCards.find((rateCard) => rateCard.id == this.timesheet.rate_card_id);
  }

  @Input()
  public absenceWarning: boolean;
  @Input()
  public doubleRegistration: boolean;
  @Input()
  public deviationsFromSchedule: TimesheetScheduleDeviations;
  @Input()
  public first: boolean;
  @Input()
  public cols: TimesheetColumns;
  @Input()
  public customFieldCols: TimesheetCustomFieldsColumns;

  @Input()
  public widths;

  public location = '';
  public surcharge = '';

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['locations'] || changes['department']) {
      this.location = this.getLocation();
    }

    if (changes['rateCards']) {
      this.surcharge = this.getSurcharge();
    }
  }

  private getLocation() {
    if (this.locations && this.department) {
      const loc = this.locations[this.department.location_id];

      return loc ? loc.name : '';
    } else {
      return '';
    }
  }

  private getSurcharge() {
    if (this.selectedRateCard) {
      const deleted = this.selectedRateCard.deleted
        ? ' - ' + format(parseDate(this.selectedRateCard.deleted_date))
        : '';

      return this.selectedRateCard.name + deleted;
    } else {
      return '';
    }
  }
}
